import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Config from "../../Config";
import Preloader from "../../Preloader/Preloader";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";

const DeleteWithdrawals = ({
  handleDeleteModal,
  fetchPendingWithdrawals,
  selectedIds,
  headers,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.alerts.loading);

  const deleteWithdrawals = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.delete(
        `${Config.baseUrl}/api/withdraw/delete/withdrawals`,
        {
          data: {
            withdrawalIds: [...selectedIds],
          },
          headers,
        }
      );
      if (response.data.success) {
        dispatch(hideLoading());
        fetchPendingWithdrawals();
        handleDeleteModal();
      }
    } catch (error) {
      dispatch(hideLoading());
      console.error("There was an error deleting the withdrawals:", error);
    }
  };

  return (
    <div className="modal-wrapper">
      {loading && <Preloader />}
      Are you sure you want to delete the selected items?
      <div className="row-buttons">
        <button className="modal-btn" onClick={handleDeleteModal}>
          CANCEL
        </button>
        <button
          className="modal-btn"
          onClick={deleteWithdrawals}
          disabled={loading}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default DeleteWithdrawals;
