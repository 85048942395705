import { useState, useRef, useEffect } from "react";
import ProfileImage from "../../assets/icons/user.png";
import UpdateRoles from "./UpdateRoles";
import "./UserDetailsModal.css";
import { useDispatch, useSelector } from "react-redux";
import { closeDetailsModal } from "../../Redux/features/UserDetailsSlice";
import formatDate from "../../utils/FormatDate";
import useUser from "../../userUser";
import axios from "axios";
import Config from "../../Config";
import SummarizedAssignmentsTable from "./SummarizedAssignmentsTable";
import headers from "../../headers";
const UserDetailsModal = () => {
  const dispatch = useDispatch();
  const [updateRolesModel, setUpdateRolesModal] = useState(false);

  const data = useSelector((state) => state.userDetailsModal.userDetails);

  const [workInProgress, setWorkInProgress] = useState([]);

  const [workInReview, setWorkInReview] = useState([]);

  const [workCompleted, setWorkCompleted] = useState([]);

  const [workInRevision, setWorkInRevision] = useState([]);

  const [cancelledWork, setCancelledWork] = useState([]);

  const showUpdatesRoleModel = () => {
    setUpdateRolesModal(!updateRolesModel);
  };

  const handleCloseViewWriter = () => {
    dispatch(closeDetailsModal());
  };

  const user = useUser();

  const isEditor = user.role === "editor";

  const contentRef = useRef(null);

  const [isInProgressOpen, setIsInProgressOpen] = useState(false);
  const [isInReviewOpen, setIsInReviewOpen] = useState(false);
  const [isCompletedOpen, setIsCompleted] = useState(false);
  const [isRevisionOpen, setIsRevisionOpen] = useState(false);
  const [isCancelledWork, setIsCancelledWork] = useState(false);

  const fetchSummarizedInProgress = async () => {
    if (!user._id) {
      console.error("User ID is undefined");
      return;
    }
    try {
      const response = await axios.get(
        `${Config.baseUrl}/api/writers/assignments/in-progress/${data._id}`,
        {
          headers,
        }
      );
      setWorkInProgress(response.data.data);
    } catch (error) {
      console.error("Failed to fetch in-progress assignments:", error);
    }
  };

  const fetchSummarizedInReview = async () => {
    if (!user._id) {
      console.error("User ID is undefined");
      return;
    }
    try {
      const response = await axios.get(
        `${Config.baseUrl}/api/writers/assignments/in-review/${data._id}`,
        {
          headers,
        }
      );
      setWorkInReview(response.data.data);
    } catch (error) {
      console.error("Failed to fetch in-review assignments:", error);
    }
  };
  const fetchSummarizedCompleted = async () => {
    if (!user._id) {
      console.error("User ID is undefined");
      return;
    }
    try {
      const response = await axios.get(
        `${Config.baseUrl}/api/writers/assignments/summarized/completed/${data._id}`,
        {
          headers,
        }
      );
      setWorkCompleted(response.data.data);
    } catch (error) {
      console.error("Failed to fetch in-review assignments:", error);
    }
  };
  const fetchSummarizedRevision = async () => {
    if (!user._id) {
      console.error("User ID is undefined");
      return;
    }
    try {
      const response = await axios.get(
        `${Config.baseUrl}/api/writers/assignments/summarized/revision/${data._id}`,
        {
          headers,
        }
      );
      const revisionWork = response.data?.data || [];
      const transformedData = revisionWork.map((item) => ({
        ...item.assignmentId,
      }));
      setWorkInRevision(transformedData);
    } catch (error) {
      console.error("Failed to fetch in-review assignments:", error);
    }
  };

  const fetchSummarizedCancelled = async () => {
    if (!user._id) {
      console.error("User ID is undefined");
      return;
    }
    try {
      const response = await axios.get(
        `${Config.baseUrl}/api/writers/assignments/summarized/cancelled/${data._id}`,
        {
          headers,
        }
      );
      setCancelledWork(response.data.data);
    } catch (error) {
      console.error("Failed to fetch in-review assignments:", error);
    }
  };

  useEffect(() => {
    fetchSummarizedInProgress();
    fetchSummarizedInReview();
    fetchSummarizedCompleted();
    fetchSummarizedRevision();
    fetchSummarizedCancelled();
    console.log(workInProgress);
  }, [user._id]);

  return (
    <div className="modal user_details">
      <div
        className="modal-wrapper"
        style={{
          padding: "0",
        }}
      >
        <div className="modal-wrapper-container">
          <p className="large-headers">{`${data?.firstName?.toUpperCase()} ${data?.lastName?.toUpperCase()}`}</p>
          <div className="row">
            <div className="profile">
              <div className="img-wrapper">
                <img
                  src={data?.profileUrl || ProfileImage}
                  alt="User Profile"
                />
              </div>
              <p className="description">
                {data?.description || (
                  <>
                    Innovative and detail-oriented Fullstack Developer with [X]
                    years of experience in designing, developing, and deploying
                    web applications. Proficient in both front-end and back-end
                    technologies, including JavaScript, React, Node.js, Express,
                    MongoDB, and SQL. Strong expertise in building scalable,
                    secure, and user-friendly applications, with a focus on
                    delivering high-quality code and seamless user experiences.
                    Adept at working in agile environments, collaborating with
                    cross-functional teams, and utilizing modern development
                    tools and best practices. Passionate about learning new
                    technologies and continuously improving technical skills.
                  </>
                )}
              </p>
            </div>
            <div className="user-body">
              <div className="detail">
                <p className="profile-description">Writer</p>
                <p className="profile-description">{data?.systemId}</p>
              </div>
              <div className="detail">
                <p className="profile-description">Bids count</p>
                <p className="profile-description">{data?.bidsCount}</p>
              </div>
              <div
                className="detail"
                onClick={() => setIsInProgressOpen(!isInProgressOpen)}
              >
                <p className="profile-description">InProgress</p>
                <p className="profile-description">{data?.inProgressCount}</p>
              </div>
              <div
                className={`assignment-container ${
                  isInProgressOpen ? "open" : "closed"
                }`}
                ref={contentRef}
              >
                {" "}
                <SummarizedAssignmentsTable
                  orders={workInProgress}
                  status={"inprogress"}
                />
              </div>
              <div
                className="detail"
                onClick={() => setIsInReviewOpen(!isInReviewOpen)}
              >
                <p className="profile-description">InReview</p>
                <p className="profile-description">{data?.inReviewCount}</p>
              </div>

              <div
                className={`assignment-container ${
                  isInReviewOpen ? "open" : "closed"
                }`}
                ref={contentRef}
              >
                <SummarizedAssignmentsTable
                  orders={workInReview}
                  status={"inreview"}
                />
              </div>

              <div
                className="detail"
                onClick={() => setIsCompleted(!isCompletedOpen)}
              >
                <p className="profile-description">Completed</p>
                <p className="profile-description">{data?.completedCount}</p>
              </div>
              <div
                className={`assignment-container ${
                  isCompletedOpen ? "open" : "closed"
                }`}
                ref={contentRef}
              >
                {" "}
                <SummarizedAssignmentsTable
                  orders={workCompleted}
                  status={"completed"}
                />
              </div>
              <div
                className="detail"
                onClick={() => setIsRevisionOpen(!isRevisionOpen)}
              >
                <p className="profile-description">Revision</p>
                <p className="profile-description">{data?.revisionCount}</p>
              </div>
              <div
                className={`assignment-container ${
                  isRevisionOpen ? "open" : "closed"
                }`}
                ref={contentRef}
              >
                {" "}
                <SummarizedAssignmentsTable
                  orders={workInRevision}
                  status={"inrevision"}
                />
              </div>
              <div
                className="detail"
                onClick={() => setIsCancelledWork(!isCancelledWork)}
              >
                <p className="profile-description">Cancelled</p>
                <p className="profile-description">
                  {data?.cancelledOrdersCount}
                </p>
              </div>
              <div
                className={`assignment-container ${
                  isCancelledWork ? "open" : "closed"
                }`}
                ref={contentRef}
              >
                <SummarizedAssignmentsTable
                  orders={cancelledWork}
                  status={"cancelled"}
                />
              </div>
              <div className="detail">
                <p className="profile-description">Created</p>
                <p className="profile-description">
                  {formatDate(data?.createdAt)}
                </p>
              </div>
              <div className="detail">
                <p className="profile-description">Roles</p>
                <p className="profile-description">
                  {data?.role === "admin"
                    ? "writer/admin"
                    : data?.role === "editor"
                    ? "editor/writer"
                    : "writer"}
                </p>
              </div>
              {!isEditor && (
                <div className="detail">
                  <p className="profile-description">Amount</p>
                  <p className="profile-description">Sh{data?.amount}</p>
                </div>
              )}
              {!isEditor && (
                <button className="update" onClick={showUpdatesRoleModel}>
                  MANAGE ROLES
                </button>
              )}
            </div>
          </div>
          {updateRolesModel && (
            <UpdateRoles showUpdatesRoleModel={showUpdatesRoleModel} />
          )}
          <div className="table_wrapper">
            <table>
              <thead>
                <tr>
                  <td>EMAIL</td>
                  <td>PHONE NO</td>
                  <td>EDUCATION LEVEL</td>
                  <td>QUALIFICATIONS</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.email}</td>
                  <td>{data?.phoneNo}</td>
                  <td>{data?.educationLevel}</td>
                  <td>{data?.qualifications}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button className="table-btn" onClick={handleCloseViewWriter}>
          CANCEL
        </button>
      </div>
    </div>
  );
};

export default UserDetailsModal;
