import React, { useState, useEffect } from "react";
import AccountNavbar from "../../../components/account/AccountNavbar/AccountNavbar";
import AccountSidebar from "../../../components/account/AccountSidebar/AccountSidebar";
import Config from "../../../Config";
import axios from "axios";
import JobDescription from "../../../components/Admin/JobDescription";
import "./InReview.css";
import RemainingTime from "../../../utils/RemainingTime";
import headers from "../../../headers";
import Preloader from "../../../Preloader/Preloader";

import { useSelector, useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../../Redux/features/AlertSlice";

import DownloadFilesModal from "../../../components/DownloadFilesModal";

import { openDownloadModal } from "../../../Redux/features/DownloadFilesSlice";

import RevisionHistoryModal from "../../../components/RevisionHistoryModal/RevisionHistoryModal";

import { openRevisionHistoryModal } from "../../../Redux/features/RevisionHistorySlice";

const InReview = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.alerts.loading);
  const [sidebar, showSidebar] = useState(false);
  const [inReviews, setInReviews] = useState([]);
  const [workingOrder, setWorkingOrder] = useState(null);
  const [countdowns, setCountdowns] = useState({});
  const [descriptionModal, showDescriptionModal] = useState(false);
  const handleWorkingWork = (order) => {
    setWorkingOrder(order);
  };
  const handleDescriptionModal = () => {
    showDescriptionModal(!descriptionModal);
  };
  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };

  const handleDownloadAllFiles = (files) => {
    dispatch(openDownloadModal(files));
  };
  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = inReviews?.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.dateline,
            order.time,
            order._id,
            order.charges,
            order.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [inReviews]);

  const fetchAssignmentsInReview = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(
        `${
          Config.baseUrl
        }/api/writers/assignments/inReview/${sessionStorage.getItem("userId")}`,
        {
          headers,
        }
      );
      dispatch(hideLoading());
      setInReviews(response.data.data);
    } catch (error) {
      console.log(
        `There was an error accessing the data from the backend`,
        error
      );
    }
  };

  useEffect(() => {
    fetchAssignmentsInReview();
  }, []);

  return (
    <div className="account inreview withdrawal">
      <AccountNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="IN REVIEW"
      />
      <AccountSidebar sidebar={sidebar} />
      <p className="empty"></p>
      <div className="container">
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <td>Id</td>
                <td>Page</td>
                <td>Words</td>
                <td>Paper details</td>
                <td>Deadline</td>
                <td>CompletedAt</td>
                <td>Charges(SH)</td>
                <td>Penalty</td>
                <td>(Sh)Payable</td>
                <td>DueTime</td>
                <td>
                  <p>Original</p>
                  <p>Instructions</p>
                </td>
                <td>Inrevision Instructions</td>
                <td>
                  <p>Revision Answers</p>
                </td>
                <td>Original Answers</td>
              </tr>
            </thead>
            <tbody>
              {inReviews?.map((review) => (
                <tr key={review._id}>
                  <td className="orderId">
                    <p className="times">{review?.inRevisionCounter || 0}</p>

                    <p
                      className="description"
                      style={{
                        width: "80px",
                      }}
                    >
                      {review.orderId}
                    </p>
                  </td>
                  <td>
                    <p className="description">{review.page}</p>
                  </td>
                  <td>
                    <p className="description">{review.words}</p>
                  </td>
                  <td>
                    <p
                      className="description"
                      style={{ color: "var(--blue)", minWidth: "250px" }}
                    >
                      Subject:{review.subject}
                    </p>
                    <p className="description">Category:{review.category}</p>
                  </td>
                  <td>
                    <p className="description">
                      {new Date(review.dateline).toLocaleDateString()}
                    </p>
                  </td>
                  <td>
                    <p>
                      {review?.completedAt
                        ? review?.completedAt
                        : "Not completed"}
                    </p>
                  </td>

                  <td>
                    <p className="description">{review.charges}</p>
                  </td>
                  <td>
                    <p className="description">{review.penalty}</p>
                  </td>
                  <td>
                    <p className="description">{`${
                      parseFloat(review.charges) - parseFloat(review.penalty)
                    }`}</p>
                  </td>
                  <td className="due-time">
                    <div className="time-container">
                      <p
                        className="description"
                        style={{ color: "var(--blue)", minWidth: "170px" }}
                      >
                        Deadline:
                        {review.dateline.split("T")[0]} at {review.time}
                      </p>

                      {countdowns[review._id]?.timeString}
                    </div>
                  </td>

                  <td>
                    <div className="description-buttons">
                      {review?.description && (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleWorkingWork({
                              subject: review.subject,
                              description: review.description,
                            });
                            handleDescriptionModal();
                          }}
                          style={{
                            background: "var(--blue)",
                            border: "2px solid var(--blue)",
                          }}
                        >
                          Description
                        </button>
                      )}
                      {review.files.length !== 0 ? (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleDownloadAllFiles(review.files);
                          }}
                        >
                          Get files
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="description-buttons">
                      {review?.inRevisionComments && (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleRevisionOrder(review?.inRevisionComments);
                          }}
                          style={{
                            background: "var(--blue)",
                            border: "2px solid var(--blue)",
                          }}
                        >
                          Description
                        </button>
                      )}
                      {review?.inRevisionFiles.length !== 0 ? (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleDownloadAllFiles(review?.inRevisionFiles);
                          }}
                        >
                          Get files
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="description-buttons">
                      {review?.revisionAnswersDescriptions?.length > 0 && (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleRevisionOrder(
                              review?.revisionAnswersDescriptions
                            );
                          }}
                          style={{
                            background: "var(--blue)",
                            border: "2px solid var(--blue)",
                          }}
                        >
                          Description
                        </button>
                      )}
                      {review?.answerFiles?.length !== 0 ? (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleDownloadAllFiles(review?.answerFiles);
                          }}
                        >
                          Get files
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="description-buttons">
                      {review?.originalAnswerDescription && (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleWorkingWork({
                              subject: review.subject,
                              description: review?.originalAnswerDescription,
                            });
                            handleDescriptionModal();
                          }}
                          style={{
                            background: "var(--blue)",
                            border: "2px solid var(--blue)",
                          }}
                        >
                          Description
                        </button>
                      )}
                      {review?.originalAnswers?.length !== 0 ? (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleDownloadAllFiles(review?.originalAnswers);
                          }}
                        >
                          Get files
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={`modal job ${descriptionModal ? "active" : null}`}>
          <JobDescription
            workingOrder={workingOrder}
            handleDescriptionModal={handleDescriptionModal}
          />
        </div>
      </div>
      {loading && <Preloader />}
      {displayDownloadModal && <DownloadFilesModal />}
      <RevisionHistoryModal />
    </div>
  );
};

export default InReview;
