import AdminSidebar from "../../components/Admin/AdminSidebar";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Config from "../../Config";
import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./submittedOrders.css";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import { useSelector, useDispatch } from "react-redux";
import Preloader from "../../Preloader/Preloader";
import RequestRevisionModal from "../../components/Admin/RequestRevisionModal";
import JobDescription from "../../components/Admin/JobDescription";
import formatDate from "../../utils/FormatDate";
import headers from "../../headers";
import "./SubmittedOrders.css";
import { openDownloadModal } from "../../Redux/features/DownloadFilesSlice";
import DownloadFilesModal from "../../components/DownloadFilesModal";
import RemainingTime from "../../utils/RemainingTime";
import { openCommentModal } from "../../Redux/features/CommentSlice";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import { useParams } from "react-router-dom";
import RevisionHistoryModal from "../../components/RevisionHistoryModal/RevisionHistoryModal";
import { openRevisionHistoryModal } from "../../Redux/features/RevisionHistorySlice";
const SubmittedOrders = () => {
  const loading = useSelector((state) => state.alerts.loading);
  const [countdowns, setCountdowns] = useState({});
  const dispatch = useDispatch();
  const [sidebar, showSidebar] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filterText, setFilterText] = useState("");

  const showCommentModal = (order) => {
    dispatch(openCommentModal(order));
  };

  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );

  const [workingOrder, setWorkingOrder] = useState([]);

  const [filteredOrders, setFilteredOrders] = useState("");

  const [requestRevisionModal, setInRequestRevisionModal] = useState(false);
  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };

  const [jobDescription, showJobDescription] = useState(false);

  const handleDownloadAllFiles = (files) => {
    dispatch(openDownloadModal(files));
  };

  const handleDescriptionModal = () => {
    showJobDescription(!jobDescription);
  };

  const fetchData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(`${Config.baseUrl}/api/answers/get`, {
        headers,
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setOrders(response.data.data);
      }
    } catch (error) {
      console.error(`There was an error: ${error}`);
    }
  };
  useEffect(() => {
    const filtered = orders?.filter((order) => {
      const orderIdMatch =
        order?.orderId?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        "";
      const subjectMatch =
        order?.subject?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        "";
      return orderIdMatch || subjectMatch;
    });
    setFilteredOrders(filtered);
  }, [filterText, orders]);

  const completeOrderButton = async (assignmentId, userId, amount) => {
    try {
      const response = await axios.post(
        `${Config.baseUrl}/api/writers/complete/assignment/${userId}`,

        { assignmentId, amount },

        { headers }
      );
      if (response.data.success) {
        toast.success(response.data.message);

        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while completing the order.");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = orders.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.assignmentId.dateline,
            order.assignmentId.time,
            order.assignmentId.charges,
            order.assignmentId.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [orders]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const handleInRevisionModal = () => {
    setInRequestRevisionModal(!requestRevisionModal);
  };

  const handleWorkingOrder = (order) => {
    setWorkingOrder(order);
  };

  const { orderId } = useParams();

  useEffect(() => {
    if (orderId && orders.length > 0) {
      const element = document.getElementById(`order-${orderId}`);
      element.style.background = "var(--blue)";
      const tableDescription = element.getElementsByTagName("p");
      for (let i = 0; i < tableDescription.length; i++) {
        tableDescription[i].style.color = "#fff";
      }
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [orderId, orders]);

  return (
    <div className="admin submitted">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="ORDERS IN REVIEW"
      />
      <AdminSidebar sidebar={sidebar} />
      <div className="container">
        <p className="empty"></p>
        <div className="search_container">
          <div className="input-group in-review">
            <input
              type="text"
              name="search"
              id=""
              placeholder="Filter by orderId or subject"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <button>
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <td>Order</td>
                <td>Client</td>
                <td>Writer</td>
                <td>Paper Details</td>
                <td>Created</td>
                <td>Submitted</td>
                <td>Deadline</td>
                <td>State</td>
                <td>Work</td>
                <td>Charges</td>
                <td>Penalties</td>
                <td>(Sh.)Payable</td>
                <td>Original Instructions</td>
                <td>InRevision Instructions</td>
                <td>Revision Answers</td>
                <td>Original Answers</td>
                <td>Revision</td>
                <td>Completed?</td>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.length === 0 ? (
                <tr>
                  <td colSpan="8">
                    <p className="description">
                      There are no submitted orders!
                    </p>
                  </td>
                </tr>
              ) : (
                filteredOrders?.map((order) =>
                  !order?.assignmentId?.completed &&
                  !order?.assignmentId?.inRevision &&
                  !order?.inRevision &&
                  order.category !== "draft" ? (
                    <tr
                      key={order._id}
                      id={`order-${order?.assignmentId?.orderId}`}
                    >
                      <td className="orderId">
                        <p className="times">
                          <p className="superscript">Revisions</p>

                          <p>{order?.assignmentId?.inRevisionCounter}</p>
                        </p>
                        <p className="description">
                          {order?.assignmentId
                            ? order?.assignmentId?.orderId
                            : "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="description" style={{ minWidth: "80px" }}>
                          {order?.assignmentId?.clientName}
                        </p>
                      </td>
                      <td>
                        <p className="description" style={{ minWidth: "80px" }}>
                          {" "}
                          {order?.writerId
                            ? `${order?.writerId?.firstName} ${order?.writerId?.lastName}`
                            : "N/A"}
                        </p>
                        <p className="description">
                          {`ID:${order?.writerId.systemId} `}
                        </p>
                      </td>

                      <td>
                        <p
                          className="description"
                          style={{ color: "var(--blue)", minWidth: "210px" }}
                        >
                          Subject:{order?.assignmentId?.subject || "N/A"}
                        </p>
                        <p className="description">
                          Category:{order?.assignmentId?.category || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "180px" }}
                        >
                          {formatDate(order?.createdAt)}
                        </p>
                      </td>
                      <td>
                        {order.submittedAt && (
                          <p
                            className="description"
                            style={{ minWidth: "180px" }}
                          >
                            {formatDate(order?.submittedAt)}
                          </p>
                        )}
                      </td>
                      <td>
                        <p className="description">
                          {order?.assignmentId?.dateline.split("T")[0]} at{" "}
                          {order?.assignmentId?.time}
                        </p>
                        <p
                          className="description"
                          style={{ minWidth: "180px" }}
                        >
                          {countdowns[order._id]?.timeString}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {order?.category || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          Words:{order?.assignmentId?.words || "N/A"}
                        </p>
                        <p className="description">
                          Pages:{order?.assignmentId?.page || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          Sh:{order?.assignmentId?.charges || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {order?.assignmentId?.penalty || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="description">{`${
                          parseFloat(order?.assignmentId?.charges) -
                          parseFloat(order?.assignmentId?.penalty)
                        }`}</p>
                      </td>
                      <td>
                        {order?.assignmentId?.files?.length !== 0 && (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(order?.assignmentId?.files)
                            }
                          >
                            Files
                          </button>
                        )}
                        {order?.assignmentId?.description && (
                          <button
                            className="table-btn"
                            style={{
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--white)",
                            }}
                            onClick={() => {
                              handleDescriptionModal();
                              handleWorkingOrder({
                                subject: order?.assignmentId?.subject,
                                description: order?.assignmentId?.description,
                              });
                            }}
                          >
                            Description
                          </button>
                        )}
                      </td>
                      <td>
                        {order?.assignmentId?.inRevisionFiles?.length !== 0 && (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(
                                order?.assignmentId?.inRevisionFiles
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                        {order?.assignmentId?.inRevisionComments?.length !==
                          0 && (
                          <button
                            className="table-btn"
                            style={{
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--white)",
                            }}
                            onClick={() => {
                              handleRevisionOrder(
                                order?.assignmentId?.inRevisionComments
                              );
                            }}
                          >
                            Description
                          </button>
                        )}
                      </td>
                      <td>
                        {order?.assignmentId?.answerFiles?.length !== 0 && (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(
                                order?.assignmentId?.answerFiles
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                        {order?.assignmentId?.revisionAnswersDescriptions
                          ?.length !== 0 && (
                          <button
                            className="table-btn"
                            style={{
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--white)",
                            }}
                            onClick={() => {
                              handleRevisionOrder(
                                order?.assignmentId?.revisionAnswersDescriptions
                              );
                            }}
                          >
                            Description
                          </button>
                        )}
                      </td>
                      <td>
                        {order?.assignmentId?.originalAnswers?.length > 0 && (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(
                                order?.assignmentId?.originalAnswers
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                        {order?.assignmentId?.originalAnswerDescription && (
                          <button
                            className="table-btn"
                            style={{
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--white)",
                            }}
                            onClick={() => {
                              handleDescriptionModal();
                              handleWorkingOrder({
                                subject: order?.assignmentId?.subject,
                                description:
                                  order?.assignmentId
                                    ?.originalAnswerDescription,
                              });
                            }}
                          >
                            Description
                          </button>
                        )}
                      </td>

                      <td>
                        {order?.category === "final" ? (
                          <>
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleWorkingOrder({
                                  answerId: order?._id,
                                  email: order?.writerId?.email || "",
                                  orderId: order?.assignmentId?._id || "",
                                  subject: order?.assignmentId?.subject || "",
                                  dateline: order?.assignmentId?.dateline || "",
                                  time: order?.assignmentId?.time || "",
                                });
                                handleInRevisionModal();
                              }}
                              style={{
                                border: "2px solid var(--success-color)",
                              }}
                            >
                              Request
                            </button>

                            {order?.assignmentId?.inRevisionFiles?.length >
                              0 && (
                              <button
                                className="table-btn"
                                style={{ marginTop: "6px" }}
                                onClick={() =>
                                  handleDownloadAllFiles(
                                    order?.assignmentId?.inRevisionFiles
                                  )
                                }
                              >
                                Files
                              </button>
                            )}
                            {order?.assignmentId?.inRevisionComment && (
                              <button
                                className="table-btn"
                                style={{
                                  background: "var(--blue)",
                                  border: "2px solid var(--blue)",
                                  marginTop: "5px",
                                }}
                                onClick={() => {
                                  showCommentModal({
                                    comment:
                                      order?.assignmentId?.inRevisionComment,
                                  });
                                }}
                              >
                                comment
                              </button>
                            )}
                          </>
                        ) : (
                          <p className="description">A draft</p>
                        )}
                      </td>
                      <td>
                        {order?.category === "final" ? (
                          <button
                            className="table-btn"
                            onClick={() => {
                              completeOrderButton(
                                order?.assignmentId?._id,
                                order?.writerId?._id,
                                order?.assignmentId?.charges
                              );
                            }}
                          >
                            CONFIRM
                          </button>
                        ) : (
                          <p className="description">A draft</p>
                        )}
                      </td>
                    </tr>
                  ) : null
                )
              )}
            </tbody>
          </table>
        </div>
        <div
          className={`modal request-revision ${
            requestRevisionModal ? "active" : null
          }`}
        >
          <RequestRevisionModal
            handleInRevisionModal={handleInRevisionModal}
            workingOrder={workingOrder}
            fetchData={fetchData}
          />
        </div>
        <div
          className={`modal description-modal ${
            jobDescription ? "active" : null
          }`}
        >
          <JobDescription
            handleDescriptionModal={handleDescriptionModal}
            workingOrder={workingOrder}
          />
        </div>
      </div>
      {displayDownloadModal && <DownloadFilesModal />}
      {loading && <Preloader />}
      <CommentsModal />
      <RevisionHistoryModal />
    </div>
  );
};

export default SubmittedOrders;
