import React, { useState, useCallback } from "react";
import "./RequestRevisionModal.css";
import UploadIcon from "../../assets/icons/upload.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Config from "../../Config";
import Preloader from "../../Preloader/Preloader";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import checkIfDatelineIsReached from "../../utils/CheckIfDatelineIsReached";
import { DateTime } from "luxon";

const RequestRevisionModal = React.memo(
  ({ handleInRevisionModal, workingOrder, fetchData }) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.alerts.loading);
    const [files, setFiles] = useState([]);
    const [comment, setComment] = useState("");
    const [newDateline, setNewDateline] = useState("");
    const [newTime, setNewTime] = useState("");

    const handleFileChange = useCallback((event) => {
      const selectedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    }, []);

    const handleDeleteFile = useCallback((index) => {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }, []);

    const setInRevision = async () => {
      const headers = {
        "x-api-key": process.env.REACT_APP_API_KEY,
      };
      try {
        dispatch(showLoading());
        const formData = new FormData();
        formData.append("comment", comment);
        formData.append("email", workingOrder.email);
        formData.append("orderId", workingOrder.orderId);
        if (newDateline) formData.append("deadline", newDateline);
        if (newTime) formData.append("time", newTime);
        files.forEach((file) => {
          formData.append("files", file);
        });

        for (const pair of formData.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }
        const response = await axios.put(
          `${Config.baseUrl}/api/answers/${workingOrder.answerId}/update/revision`,
          formData,
          { headers }
        );
        dispatch(hideLoading());
        if (!response.data.success) {
          alert(response.data.message);
        } else {
          fetchData();
          handleInRevisionModal();
          setComment("");
          setFiles([]);
          alert(response.data.message);
        }
      } catch (error) {
        dispatch(hideLoading());
        alert("There was a network problem adding the assignment");
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      if (!comment) {
        alert("Comment is required");
        return;
      }
      const nowInNairobi = DateTime.now().setZone("Africa/Nairobi");
      const selectedDate =
        DateTime.fromISO(newDateline).setZone("Africa/Nairobi");
      const selectedTime = DateTime.fromISO(
        `${newDateline}T${newTime}`
      ).setZone("Africa/Nairobi");
      if (checkIfDatelineIsReached(workingOrder.dateline, workingOrder.time)) {
        if (!newDateline || !newTime) {
          alert(
            "New dateline and time are required as the current deadline has expired."
          );
          return;
        }
        if (selectedDate < nowInNairobi.startOf("day")) {
          alert("The new dateline cannot be in the past.");
          return;
        }
        if (
          selectedDate.hasSame(nowInNairobi, "day") &&
          selectedTime < nowInNairobi
        ) {
          alert("The selected time must be in future for today's date");
          return;
        }
      } else if (
        newDateline &&
        selectedDate.hasSame(nowInNairobi, "day") &&
        selectedTime < nowInNairobi
      ) {
        alert("The selected time must be in the future.");
        return;
      }

      setInRevision();
    };
    const todayInNairobi = DateTime.now().setZone("Africa/Nairobi").toISODate();
    return (
      <>
        <form className="revision modal-wrapper" onSubmit={handleSubmit}>
          <div className="modal-wrapper-container">
            <p className="modal-header">{`REQUEST REVISION ON ${workingOrder?.subject?.toUpperCase()}`}</p>

            <div className="row">
              <div className="input-group">
                <p>Enter new dateline</p>
                <input
                  type="date"
                  name="dateline"
                  value={newDateline}
                  onChange={(e) => setNewDateline(e.target.value)}
                  min={todayInNairobi}
                />
              </div>
              <div className="input-group">
                <p>Enter new time</p>
                <input
                  type="time"
                  name="time"
                  value={newTime}
                  onChange={(e) => setNewTime(e.target.value)}
                />
              </div>
            </div>

            <div className="input_group">
              <textarea
                name="comment"
                placeholder="What should the writer consider?"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                cols={10}
                rows={15}
                required
              ></textarea>
            </div>

            <div className="upload-container">
              <input
                type="file"
                id="file-input"
                multiple
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <img
                src={UploadIcon}
                alt="Upload"
                className="upload-icon"
                onClick={() => document.getElementById("file-input").click()}
              />
            </div>

            <div className="uploaded-files" style={{ margin: "1.5rem 0rem" }}>
              {files.map((file, index) => (
                <div key={index} className="file-preview">
                  <span>{file.name}</span>
                  <button
                    type="button"
                    className="table-btn"
                    onClick={() => handleDeleteFile(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>

            <div className="row-buttons">
              <button
                type="button"
                className="modal-btn"
                onClick={handleInRevisionModal}
              >
                CANCEL
              </button>
              <button type="submit" className="modal-btn">
                OK
              </button>
            </div>
          </div>
        </form>

        {loading && <Preloader />}
      </>
    );
  }
);

export default RequestRevisionModal;
