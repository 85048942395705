import AdminSidebar from "../../components/Admin/AdminSidebar";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Config from "../../Config";
import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./submittedOrders.css";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import { useSelector, useDispatch } from "react-redux";
import Preloader from "../../Preloader/Preloader";
import RequestRevisionModal from "../../components/Admin/RequestRevisionModal";
import JobDescription from "../../components/Admin/JobDescription";
import formatDate from "../../utils/FormatDate";
import headers from "../../headers";
import "./SubmittedOrders.css";
import { openDownloadModal } from "../../Redux/features/DownloadFilesSlice";
import DownloadFilesModal from "../../components/DownloadFilesModal";
import RemainingTime from "../../utils/RemainingTime";
const Drafts = () => {
  const loading = useSelector((state) => state.alerts.loading);
  const dispatch = useDispatch();
  const [sidebar, showSidebar] = useState(false);
  const [orders, setOrders] = useState([]);
  const [countdowns, setCountdowns] = useState({});
  const [workingOrder, setWorkingOrder] = useState([]);
  const [jobDescription, showJobDescription] = useState(false);
  const handleDescriptionModal = () => {
    showJobDescription(!jobDescription);
  };
  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const [requestRevisionModal, setInRequestRevisionModal] = useState(false);
  const handleDownloadAllFiles = (files) => {
    dispatch(openDownloadModal(files));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = orders.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.assignmentId.dateline,
            order.assignmentId.time,
            order.assignmentId.charges,
            order.assignmentId.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [orders]);

  const deleteAnswer = async (answerId) => {
    try {
      dispatch(showLoading());
      const response = await axios.delete(
        `${Config.baseUrl}/api/answers/${answerId}/delete/answer`,
        {
          headers,
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("There was an error deleting the answer.");
      console.error(`There was an error: ${error}`);
    }
  };
  const fetchData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(`${Config.baseUrl}/api/answers/get`, {
        headers,
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setOrders(response.data.data);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(`There was an error: ${error}`);
    }
  };

  useEffect(() => {

    fetchData();
    
  }, []);

  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const handleInRevisionModal = () => {
    setInRequestRevisionModal(!requestRevisionModal);
  };
  const handleWorkingOrder = (order) => {
    setWorkingOrder(order);
  };
  return (
    <div className="admin submitted">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="DRAFTS"
      />
      <AdminSidebar sidebar={sidebar} />
      <div className="container">
        <p className="empty"></p>
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <td>Order</td>
                <td>Client</td>
                <td>Writer</td>
                <td>Details</td>
                <td>Created</td>
                <td>Submitted</td>
                <td>State</td>
                <td>Work</td>
                <td>Charges</td>
                <td>Deadline</td>
                <td>Penalties</td>
                <td>Revision</td>
                <td>Revision Instruction</td>
                <td>Revision Answers</td>
                <td>Original Answers</td>
                <td>Completed?</td>
                <td>Delete</td>
              </tr>
            </thead>
            <tbody>
              {orders.length === 0 ? (
                <tr>
                  <td colSpan="8">
                    <p className="description">
                      There are no submitted orders!
                    </p>
                  </td>
                </tr>
              ) : (
                orders.map((order) =>
                  order?.category === "draft" ? (
                    <tr key={order._id} className="orderId">
                      <td>
                        <p className="times">
                          <p className="superscript">Revisions</p>
                          <p>{order?.assignmentId?.inRevisionCounter}</p>
                        </p>
                        <p className="description">
                          {order?.assignmentId
                            ? order?.assignmentId?.orderId
                            : "N/A"}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "120px" }}
                        >
                          {order?.assignmentId?.clientName}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "120px" }}
                        >
                          {" "}
                          {order?.writerId
                            ? `${order?.writerId?.firstName} ${order?.writerId?.lastName}`
                            : "N/A"}
                        </p>
                        <p
                          className="description"
                          style={{ minWidth: "120px" }}
                        >
                          {`ID:${order?.writerId.systemId} `}
                        </p>
                      </td>

                      <td>
                        <p
                          className="description"
                          style={{ color: "var(--blue)", minWidth: "150px" }}
                        >
                          Subject:{order?.assignmentId?.subject || "N/A"}
                        </p>

                        <p className="description">
                          Description:{order?.category || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "140px" }}
                        >
                          {formatDate(order?.createdAt)}
                        </p>
                      </td>
                      <td>
                        {order.submittedAt && (
                          <p
                            className="description"
                            style={{ minWidth: "140px" }}
                          >
                            {formatDate(order?.submittedAt)}
                          </p>
                        )}
                      </td>
                      <td>
                        <p className="description">
                          {order?.category || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          Word:{order?.assignmentId?.words || "N/A"}
                        </p>
                        <p className="description">
                          Pages:{order?.assignmentId?.page || "N/A"}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          Sh:{order?.assignmentId?.charges}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {order?.assignmentId.dateline.split("T")[0]} at {""}{" "}
                          {order?.assignmentId?.time}
                        </p>
                        <p
                          className="description"
                          style={{ minWidth: "150px" }}
                        >
                          {countdowns[order._id]?.timeString}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {order?.assignmentId?.penalty || "N/A"}
                        </p>
                      </td>
                      <td>
                        {order?.category === "final" ? (
                          <button
                            className="table-btn setInRevision"
                            onClick={() => {
                              handleWorkingOrder({
                                answerId: order?._id,
                                email: order?.writerId?.email || "",
                                orderId: order?.assignmentId?._id || "",
                                subject: order?.assignmentId?.subject || "",
                              });
                              handleInRevisionModal();
                            }}
                            style={{
                              border: "2px solid var(--success-color)",
                            }}
                          >
                            Request
                          </button>
                        ) : (
                          <p className="description">A draft</p>
                        )}
                      </td>
                      <td>
                        {order?.assignmentId?.inRevisionFiles?.length !== 0 && (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(
                                order?.assignmentId?.inRevisionFiles
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                        {order?.description && (
                          <button
                            className="table-btn"
                            style={{
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--white)",
                            }}
                            onClick={() => {
                              handleDescriptionModal();
                              handleWorkingOrder({
                                subject: order?.assignmentId?.subject,
                                description: order?.description,
                              });
                            }}
                          >
                            Description
                          </button>
                        )}
                      </td>

                      <td>
                        {order?.assignmentId?.answerFiles?.length !== 0 && (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(
                                order?.assignmentId?.answerFiles
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                        {order?.description && (
                          <button
                            className="table-btn"
                            style={{
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--white)",
                            }}
                            onClick={() => {
                              handleDescriptionModal();
                              handleWorkingOrder({
                                subject: order?.assignmentId?.subject,
                                description: order?.description,
                              });
                            }}
                          >
                            Description
                          </button>
                        )}
                      </td>
                      <td>
                        {order?.assignmentId?.originalAnswers?.length !== 0 && (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(
                                order?.assignmentId?.originalAnswers
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                        {order?.assignmentId?.originalAnswerDescription && (
                          <button
                            className="table-btn"
                            style={{
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--white)",
                            }}
                            onClick={() => {
                              handleDescriptionModal();
                              handleWorkingOrder({
                                subject: order?.assignmentId?.subject,
                                description:
                                  order?.assignmentId
                                    ?.originalAnswerDescription,
                              });
                            }}
                          >
                            Description
                          </button>
                        )}
                      </td>
                      <td>
                        {order?.category === "final" ? (
                          <button className="table-btn">CONFIRM</button>
                        ) : (
                          <p className="description">A draft</p>
                        )}
                      </td>

                      <td>
                        <button
                          className="table-btn"
                          onClick={() => deleteAnswer(order?._id)}
                        >
                          DELETE
                        </button>
                      </td>
                    </tr>
                  ) : null
                )
              )}
            </tbody>
          </table>
        </div>
        <div
          className={`modal request-revision ${
            requestRevisionModal ? "active" : null
          }`}
        >
          <RequestRevisionModal
            handleInRevisionModal={handleInRevisionModal}
            workingOrder={workingOrder}
            fetchData={fetchData}
          />
        </div>
        <div
          className={`modal description-modal ${
            jobDescription ? "active" : null
          }`}
        >
          <JobDescription
            handleDescriptionModal={handleDescriptionModal}
            workingOrder={workingOrder}
          />
        </div>
      </div>

      {loading && <Preloader />}

      {displayDownloadModal && <DownloadFilesModal />}
    </div>
  );
};

export default Drafts;
