import React from "react";
import { Link } from "react-router-dom";
import "./SummarizedAssignmentsTable.css";

const SummarizedAssignmentsTable = ({ orders, status }) => {
  const statusLower = status.toLowerCase();

  const getLink = (orderId) => {
    switch (statusLower) {
      case "inprogress":
        return `/admin/assignments-inprogress/${orderId}`;
      case "inreview":
        return `/admin/orders-in-review/${orderId}`;
      case "completed":
        return `/admin/orders/completed-orders/${orderId}`;
      case "inrevision":
        return `/admin/orders/revision/${orderId}`;
      case "cancelled":
        return `/admin/orders/cancelled/${orderId}`;
      default:
        return "#";
    }
  };

  return (
    <table
      className="summarized-table"
      style={{
        height: "fit-content",
      }}
    >
      <thead>
        <tr>
          <td>ID</td>
          <td>Subject</td>
          <td>Category</td>
          <td>View</td>
        </tr>
      </thead>
      <tbody>
        {orders?.map((order) => (
          <tr key={order?.orderId || order?.systemId}>
            <td>
              <p className="description">{order?.orderId || order?.systemId}</p>
            </td>
            <td>
              <p className="description" style={{ width: "170px" }}>
                {order?.subject}
              </p>
            </td>
            <td>
              <p className="description" style={{ width: "170px" }}>
                {order?.category}
              </p>
            </td>
            <td>
              <Link to={getLink(order?.orderId || order?.systemId)}>
                <button className="table-btn">
                  View <i className="fa-solid fa-arrow-right"></i>
                </button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SummarizedAssignmentsTable;
