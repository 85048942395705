const checkIfDatelineIsReached = (deadline, time) => {
  const now = new Date().toLocaleString("en-US", {
    timeZone: "Africa/Nairobi",
  });
  const currentTime = new Date(now).getTime();
  let formattedTime = time;
  if (formattedTime?.length === 5) {
    formattedTime += ":00";
  }
  const dueDateTimeString = `${deadline?.split("T")[0]}T${formattedTime}`;
  const dueDateTime = new Date(dueDateTimeString).getTime();

  if (isNaN(dueDateTime)) {
    console.error("Invalid date/time:", dueDateTimeString);
    return false;
  }
  return currentTime > dueDateTime;
};

export default checkIfDatelineIsReached;
