import React, { useState } from "react";
import Preloader from "../../Preloader/Preloader";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Config from "../../Config";
import headers from "../../headers";
import "./CancelWorkModal.css";

const CancelWorkModal = ({ workToDelete, handleCancelModal, fetchWork }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.alerts.loading);

  const [refundPercentage, setRefundPercentage] = useState(
    workToDelete?.completed ? "" : 0
  );
  const [comments, setComments] = useState("");

  const [errors, setErrors] = useState({
    refundPercentage: "",
    comments: "",
  });

  const handleRefundPercentageChange = (e) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      refundPercentage: "",
    }));
    setRefundPercentage(e.target.value);
  };

  const handleCommentsChange = (e) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      comments: "",
    }));
    setComments(e.target.value);
  };

  const validateForm = () => {
    let isValid = true;
    const errors = { refundPercentage: "", comments: "" };

    if (
      workToDelete?.completed &&
      (!refundPercentage ||
        isNaN(refundPercentage) ||
        refundPercentage < 0 ||
        refundPercentage > 100)
    ) {
      errors.refundPercentage =
        "Refund percentage must be a number between 0 and 100";
      isValid = false;
    }

    if (!comments.trim()) {
      errors.comments = "Comments cannot be empty";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const cancelOrder = async () => {
    if (!validateForm()) return;

    try {
      dispatch(showLoading());
      const response = await axios.post(
        `${Config.baseUrl}/api/cancelled/order/cancel/${workToDelete._id}`,
        { refundPercentage, comments, assignedTo: workToDelete.assignedTo },
        { headers }
      );
      if (response.data.success) {
        fetchWork();
        alert("Order cancelled successfully!");
      } else {
        console.log("Failed to cancel the order");
      }
    } catch (error) {
      console.error("There was an error cancelling the order", error);
    } finally {
      dispatch(hideLoading());
      handleCancelModal();
    }
  };

  return (
    <>
      <div className="cancel-work modal-wrapper">
        <p
          className="medium-header"
          style={{
            borderBottom: "2px solid var(--blue)",
          }}
        >{`Are you sure you want to cancel ${workToDelete?.subject}?`}</p>
        <div className="input-group">
          <input
            type="text"
            value={refundPercentage}
            placeholder="Enter the percentage of the amount to be refunded.."
            onChange={handleRefundPercentageChange}
            readOnly={!workToDelete?.completed}
          />
          {errors.refundPercentage && (
            <p className="error">{errors.refundPercentage}</p>
          )}
        </div>
        <div className="input_group">
          <textarea
            cols="10"
            rows="5"
            value={comments}
            onChange={handleCommentsChange}
            placeholder="Enter the comments"
          ></textarea>

          {errors.comments && <p className="error">{errors.comments}</p>}
        </div>
        <div className="row-buttons">
          <button className="modal-btn" onClick={handleCancelModal}>
            CANCEL
          </button>
          <button className="modal-btn" onClick={cancelOrder}>
            OK
          </button>
        </div>
      </div>

      {loading && <Preloader />}
    </>
  );
};

export default CancelWorkModal;
