import AccountNavbar from "../../../components/account/AccountNavbar/AccountNavbar";
import AccountSidebar from "../../../components/account/AccountSidebar/AccountSidebar";
import ".././Clients.css";
import Config from "../../../Config";
import headers from "../../../headers";
import axios from "axios";
import "../GetOrders/GetOrders.css";
import "./WritersCancelledOrders.css";

import formatDate from "../../../utils/FormatDate";

import { useEffect, useState } from "react";

import Preloader from "../../../Preloader/Preloader";

import { showLoading, hideLoading } from "../../../Redux/features/AlertSlice";

import { useDispatch, useSelector } from "react-redux";

import JobDescription from "../../../components/Admin/JobDescription";

import { openDownloadModal } from "../../../Redux/features/DownloadFilesSlice";

import DownloadFilesModal from "../../../components/DownloadFilesModal";

import { openCommentModal } from "../../../Redux/features/CommentSlice";

import CommentsModal from "../../../components/CommentsModal/CommentsModal";

import RevisionHistoryModal from "../../../components/RevisionHistoryModal/RevisionHistoryModal";

import { openRevisionHistoryModal } from "../../../Redux/features/RevisionHistorySlice";

const WritersCancelledOrders = () => {
  const [sidebar, showSidebar] = useState(false);

  const [workingOrder, setWorkingOrder] = useState([]);

  const [jobDescription, showJobDescription] = useState(false);

  const [cancelledOrders, setCancelledOrders] = useState([]);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.alerts.loading);

  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };

  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );

  const handleFilesDownload = (order) => {
    dispatch(openDownloadModal(order));
  };

  const handleWorkingOrder = (order) => {
    setWorkingOrder(order);
  };

  const handleDescriptionModal = () => {
    showJobDescription(!jobDescription);
  };

  const fetchCancelledOrders = async () => {
    try {
      dispatch(showLoading());

      const response = await axios.get(
        `${Config.baseUrl}/api/cancelled/writer/${sessionStorage.getItem(
          "userId"
        )}`,
        {
          headers,
        }
      );

      dispatch(hideLoading());

      if (response.data.success) {
        setCancelledOrders(response.data.data);
      }
    } catch (error) {
      dispatch(hideLoading());

      console.log(
        `An error arised fetching the cancelled orders from the backend`
      );
    }
  };

  useEffect(() => {
    fetchCancelledOrders();
  }, []);

  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const showCommentModal = (order) => {
    dispatch(openCommentModal(order));
  };

  return (
    <>
      <div className="account orders cancelled">
        <AccountNavbar
          sidebar={sidebar}
          handleShowSidebar={handleShowSidebar}
          title="CANCELLED ORDERS"
        />
        <AccountSidebar sidebar={sidebar} />
        <div className="container">
          <p className="empty"></p>
        </div>
        <div className="container">
          <div className="table_wrapper">
            <table>
              <thead>
                <tr>
                  <td>Id</td>
                  <td>Details</td>
                  <td>Work</td>
                  <td>Charges</td>
                  <td>Refund</td>
                  <td>Cancelled on</td>
                  <td>Deadline</td>
                  <td>Instructions</td>
                  <td>InRevision Instructions</td>
                  <td>InRevision Answers</td>
                  <td>Original Answers</td>
                  <td>Cancel comments</td>
                </tr>
              </thead>
              <tbody>
                {cancelledOrders?.length > 0 &&
                  cancelledOrders?.map((order) => (
                    <tr>
                      <td>
                        <p className="description">{order?.systemId}</p>
                      </td>
                      <td>
                        <p className="description"> Subject:{order?.subject}</p>

                        <p className="description">
                          Category:{order?.category}
                        </p>
                      </td>
                      <td>
                        <p className="description">Words:{order?.words}</p>
                        <p className="description">Pages:{order?.page}</p>
                      </td>
                      <td>
                        <p className="description">{order?.charges}</p>
                      </td>

                      <td>
                        <p className="description">{`${order?.refundPercentage}%`}</p>

                        <p className="description">
                          {`Deducted: ${
                            (order?.refundPercentage / 100) * order?.charges
                          } `}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {formatDate(order?.cancelledAt)}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {order?.dateline?.split("T")[0]} at {order?.time}
                        </p>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.description && (
                            <button
                              className="table-btn"
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                              onClick={() => {
                                handleWorkingOrder({
                                  subject: order?.subject,
                                  description: order?.description,
                                });
                                handleDescriptionModal();
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order?.files?.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order?.files);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.inRevisionComments?.length > 0 && (
                            <button
                              className="table-btn"
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                              onClick={() => {
                                handleRevisionOrder(order?.inRevisionComments);
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order?.inRevisionFiles?.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order?.inRevisionFiles);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.revisionAnswersDescriptions?.length > 0 && (
                            <button
                              className="table-btn"
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                              onClick={() => {
                                handleRevisionOrder(
                                  order?.revisionAnswersDescriptions
                                );
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order?.answerFiles?.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order?.answerFiles);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.answerDescription && (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleWorkingOrder({
                                  subject: order?.subject,
                                  description: order?.answerDescription,
                                });
                                handleDescriptionModal();
                              }}
                            >
                              Description
                            </button>
                          )}
                          {order?.originalAnswers?.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order?.originalAnswers);
                              }}
                            >
                              Files
                            </button>
                          ) : (
                            <p className="description">No files</p>
                          )}
                        </div>
                      </td>
                      <td>
                        <button
                          className="table-btn"
                          onClick={() => {
                            showCommentModal({ comment: order?.comments });
                          }}
                        >
                          view
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`modal description-modal ${
            jobDescription ? "active" : null
          }`}
        >
          <JobDescription
            handleDescriptionModal={handleDescriptionModal}
            workingOrder={workingOrder}
          />
        </div>

        {loading && <Preloader />}
      </div>

      {displayDownloadModal && <DownloadFilesModal />}

      <CommentsModal />
      <RevisionHistoryModal />
    </>
  );
};

export default WritersCancelledOrders;
