import React from "react";

const TermsOfUse = ({ handleTermsAccept, termsAccepted }) => {
  return (
    <div>
      <p className="card-headers">Effective Date:[09/08/2024]</p>
      <p className="card-headers">Welcome to BM Writers</p>
      <p className="description">
        These Terms of Use ("Terms") govern your use of BM Writers' web-based
        platform (the "System"). By accessing or using the System, you agree to
        comply with and be bound by these Terms. If you do not agree with these
        Terms, please refrain from using the System.
      </p>
      <p className="card-headers">1. Definitions</p>
      <p className="description">
        <strong>System</strong>: Refers to the BM Writers platform that
        facilitates the submission and management of written assignments by
        writers.
      </p>

      <p className="description">
        <strong>Writer</strong>: An individual who creates, proofreads, edits,
        revises and submits assignments through the System.
      </p>

      <p className="description">
        <strong>Assignments</strong>: Written work submitted by writers for
        clients.
      </p>
      <p className="description">
        <strong>User Content</strong>: All content created, uploaded, or
        otherwise provided by writers through the System.
      </p>

      <p className="card-headers">2. Eligibility</p>
      <p className="description">
        To use the System, you must meet the following criteria:
      </p>
      <p className="description">Be at least 18 years of age.</p>
      <p className="description">
        Possess the following qualifications; undergraduate, graduate or
        postgraduate (Masters and PhD) and be experienced as required by the
        System.
      </p>

      <p className="card-headers">3.Account Responsibilities</p>

      <p className="description">
        <strong>Account Creation</strong>: To access the System, you must create
        an account by providing accurate and complete information.
      </p>

      <p className="description">
        <strong>Accuracy</strong>: You agree to keep your account information
        current and accurate.
      </p>

      <p className="description">
        <strong>Security</strong>: You are responsible for maintaining the
        confidentiality of your account credentials and for all activities
        conducted under your account.
      </p>

      <p className="card-headers">4.Use of the System</p>
      <p className="description">
        <strong>Assignment Submission</strong>: You agree to submit assignments
        in accordance with the System's guidelines and quality standards.
      </p>
      <p className="description">
        <strong>Prohibited Content</strong>: You must not submit content that is
        plagiarized, illegal, harmful, or otherwise inappropriate.
      </p>

      <p className="description">
        <strong>Compliance</strong>: You agree to adhere to all applicable
        regulations while using the System.
      </p>

      <p className="card-headers">5.Compensation and Payment</p>

      <p className="description">
        <strong>Compensation</strong>: You will receive compensation for
        completed assignments as per the terms agreed upon within the System.
      </p>

      <p className="description">
        <strong>Payment Process</strong>: Payments will be processed through the
        Mpesa method. Any applicable fees or deductions will be disclosed.
      </p>

      <p className="card-headers">6.Intellectual Property</p>

      <p className="description">
        <strong>Ownership</strong>: You retain ownership of the content you
        create; however, you grant BM Writers a non-exclusive, royalty-free,
        perpetual, and worldwide license to use, reproduce, and distribute your
        content for the purposes of the System.
      </p>
      <p className="description">
        <strong>Licenses</strong>: The license granted to BM Writers is for the
        operational needs of the System and its functionalities.
      </p>

      <p className="card-headers">7.Termination</p>
      <p className="description">
        <strong>Termination by Writer</strong>: You may terminate your account
        at any time by writing a termination request email to the admin.
      </p>
      <p className="description">
        <strong>Termination by BM Writers</strong>: BM Writers reserves the
        right to terminate your account if you breach these Terms or engage in
        prohibited activities. You will receive notification of any such
        termination.
      </p>

      <p className="card-headers">8.Dispute Resolution</p>
      <p className="description">
        <strong>Mechanisms</strong>: Any disputes arising out of or related to
        these Terms will be resolved through mediation, arbitration or any other
        resolution mechanism determined by the System.
      </p>

      <p className="card-headers">9.Amendments</p>
      <p className="description">
        BM Writers reserves the right to amend these Terms at any time. Changes
        will be communicated to you via the System or email. Your continued use
        of the System following any amendments constitutes acceptance of the
        revised Terms.
      </p>

      <p className="card-headers">10.Contact Information</p>
      <p className="description">
        For any questions or concerns regarding these Terms, please contact BM
        Writers at bmwriters80@gmail.com.
      </p>
      <div className="input-group terms">
        <label
          htmlFor="termsAccepted"
          style={{
            color: "var(--success-color)",
            fontWeight: "600",
          }}
        >
          I have read and accept the Terms of Use(Mark checkbox)
        </label>
        <input
          type="checkbox"
          name="termsAccepted"
          checked={termsAccepted}
          onChange={handleTermsAccept}
        />
      </div>
    </div>
  );
};

export default TermsOfUse;
