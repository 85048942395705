import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displayHistoryModal: false,

  data: null,
};

const RevisionHistorySlice = createSlice({
  name: "revisionHistory",

  initialState,

  reducers: {
    openRevisionHistoryModal(state, action) {
        
      state.data = action.payload;

      state.displayHistoryModal = true;

    },
    closeRevisionHistoryModal(state) {

      state.data = null;

      state.displayHistoryModal = false;
    },
  },
});

export const { openRevisionHistoryModal, closeRevisionHistoryModal } =
  RevisionHistorySlice.actions;

export default RevisionHistorySlice;
