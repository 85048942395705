import { useState } from "react";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import AdminSidebar from "../../components/Admin/AdminSidebar";
const RefundedOrders = () => {
  const [sidebar, showSidebar] = useState();

  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };

  return (
    <div className="admin cancelled-orders">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="REFUNDED ORDERS"
      />

      <AdminSidebar sidebar={sidebar} />

      <p className="empty"></p>

      <div className="container">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <td>Index</td>
                <td>Id</td>
                <td>Name</td>
                <td>Email</td>
                <td>Email</td>
                <td>Qualifications</td>
                <td>Requested On</td>
                <td>Approve</td>
                <td>Reject</td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RefundedOrders;
