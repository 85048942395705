import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";
import "./AdminSidebar.css";
import headers from "../../headers";
import useUser from "../../userUser";

const AdminSidebar = ({ sidebar }) => {
  const [stats, setStats] = useState({
    completedOrders: 0,
    writers: 0,
    unassignedOrders: 0,
    assignedOrders: 0,
    ordersInRevision: 0,
    ordersInReview: 0,
    pendingApplications: 0,
    drafts: 0,
    canceledOrders: 0,
  });

  const getBackendStats = async () => {
    try {
      const response = await axios.get(
        `${Config.baseUrl}/api/assignments/stats/get`,
        { headers }
      );
      setStats(response.data.stats);
    } catch (error) {
      console.error("Failed to fetch backend stats:", error.message);
    }
  };

  useEffect(() => {
    getBackendStats();
  }, []);

  const user = useUser();
  const isEditor = user.role === "editor";

  return (
    <div className={`admin sidebar ${sidebar ? "active" : ""}`}>
      <div className="sidebar_navigation">
        {isEditor ? (
          <>
            <NavLink exact to="/admin/add-work" activeClassName="active">
              <p>
                <i className="fas fa-plus-circle"></i> Add Work
              </p>
            </NavLink>
            <NavLink exact to="/admin/writers" activeClassName="active">
              <p>
                <i className="fas fa-users"></i> Writers{" "}
                <sup>{stats.writers}</sup>
              </p>
            </NavLink>
            <NavLink exact to="/admin/work-order" activeClassName="active">
              <p>
                <i className="fas fa-tasks"></i> Unassigned{" "}
                <sup>{stats.unassignedOrders}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/assignments-inprogress"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-clipboard-list"></i> In Progress{" "}
                <sup>{stats.assignedOrders}</sup>
              </p>
            </NavLink>

            <NavLink exact to="/admin/orders/drafts" activeClassName="active">
              <p>
                <i className="fas fa-file-alt"></i> Drafts{" "}
                <sup>{stats.drafts}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/orders-in-review"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-calendar-check"></i> In Review{" "}
                <sup>{stats.ordersInReview}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/orders/completed-orders"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-check-circle"></i> Completed{" "}
                <sup>{stats.completedOrders}</sup>
              </p>
            </NavLink>

            <NavLink exact to="/admin/orders/revision" activeClassName="active">
              <p>
                <i className="fas fa-sync-alt"></i> In Revision{" "}
                <sup>{stats.ordersInRevision}</sup>
              </p>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink exact to="/admin/add-work" activeClassName="active">
              <p>
                <i className="fas fa-plus-circle"></i> Add Work
              </p>
            </NavLink>

            <NavLink exact to="/admin/writers" activeClassName="active">
              <p>
                <i className="fas fa-users"></i> Writers{" "}
                <sup>{stats.writers}</sup>
              </p>
            </NavLink>

            <NavLink exact to="/admin/work-order" activeClassName="active">
              <p>
                <i className="fas fa-tasks"></i> Unassigned{" "}
                <sup>{stats.unassignedOrders}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/assignments-inprogress"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-clipboard-list"></i> In Progress{" "}
                <sup>{stats.assignedOrders}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/orders/orders-adjustment"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-edit"></i> Orders Adjustment
              </p>
            </NavLink>

            <NavLink exact to="/admin/orders/drafts" activeClassName="active">
              <p>
                <i className="fas fa-file-alt"></i> Drafts{" "}
                <sup>{stats.drafts}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/orders-in-review"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-calendar-check"></i> In Review{" "}
                <sup>{stats.ordersInReview}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/orders/completed-orders"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-check-circle"></i> Completed{" "}
                <sup>{stats.completedOrders}</sup>
              </p>
            </NavLink>

            <NavLink exact to="/admin/orders/revision" activeClassName="active">
              <p>
                <i className="fas fa-sync-alt"></i> In Revision{" "}
                <sup>{stats.ordersInRevision}</sup>
              </p>
            </NavLink>

            <NavLink exact to="/admin/withdrawals" activeClassName="active">
              <p>
                <i className="fas fa-money-bill-wave"></i> Withdrawals
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/orders/cancelled"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-exclamation-triangle"></i> Cancelled{" "}
                <sup>{stats.canceledOrders}</sup>
              </p>
            </NavLink>

            <NavLink
              exact
              to="/admin/pending-applications"
              activeClassName="active"
            >
              <p>
                <i className="fas fa-file-alt"></i> Applications{" "}
                <sup>{stats.pendingApplications}</sup>
              </p>
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminSidebar;
