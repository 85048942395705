import React, { useEffect, useState } from "react";
import axios from "axios";
import AccountNavbar from "../../../components/account/AccountNavbar/AccountNavbar";
import AccountSidebar from "../../../components/account/AccountSidebar/AccountSidebar";
import Config from "../../../Config";
import ".././Clients.css";
import "../InProgress/InProgress.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubmitOrders from "../SubmitOrders/SubmitOrders";
import JobDescription from "../../../components/Admin/JobDescription";
import headers from "../../../headers";
import formatDate from "../../../utils/FormatDate";
import Preloader from "../../../Preloader/Preloader";
import { showLoading, hideLoading } from "../../../Redux/features/AlertSlice";
import { useDispatch, useSelector } from "react-redux";
import { openDownloadModal } from "../../../Redux/features/DownloadFilesSlice";
import DownloadFilesModal from "../../../components/DownloadFilesModal";
import RemainingTime from "../../../utils/RemainingTime";
import { openRevisionHistoryModal } from "../../../Redux/features/RevisionHistorySlice";
import RevisionHistoryModal from "../../../components/RevisionHistoryModal/RevisionHistoryModal";
const CompletedOrders = () => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [sidebar, showSidebar] = useState(false);
  const [workingOrder, setWorkingOrder] = useState(null);
  const [submitModal, displaySubmitModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const loading = useSelector((state) => state.alerts.loading);
  const [countdowns, setCountdowns] = useState({});

  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const [descriptionModal, showDescriptionModal] = useState(false);
  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };
  const fetchUserWork = async () => {
    if (!sessionStorage.getItem("userId")) return;
    try {
      dispatch(showLoading());
      const response = await axios.get(
        `${Config.baseUrl}/api/writers/assignments/${sessionStorage.getItem(
          "userId"
        )}/completed`,
        {
          headers,
        }
      );
      dispatch(hideLoading());
      setOrders(response.data.data);
    } catch (error) {
      console.log(
        `There was an error accessing the data from the backend ${error.message}`
      );
    }
  };

  const handleDownloadAllFiles = (files) => {
    dispatch(openDownloadModal(files));
  };

  useEffect(() => {
    if (sessionStorage.getItem("userId")) {
      fetchUserWork();
    }
  }, []);

  const handleWorkingWork = (order) => {
    setWorkingOrder(order);
  };
  const handleSubmitModal = () => {
    displaySubmitModal(!submitModal);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = orders?.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.dateline,
            order.time,
            order.charges,
            order.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [orders]);

  useEffect(() => {
    const filtered = orders?.filter((order) => {
      const orderIdMatch = order.orderId
        .toLowerCase()
        .includes(filterText.toLowerCase());
      const subjectMatch = order.subject
        .toLowerCase()
        .includes(filterText.toLowerCase());
      return orderIdMatch || subjectMatch;
    });
    setFilteredOrders(filtered);
  }, [filterText, orders]);

  const handleDescriptionModal = () => {
    showDescriptionModal(!descriptionModal);
  };

  return (
    <div className="progress account">
      <AccountNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="COMPLETED ORDERS"
      />
      <p className="empty"></p>
      <AccountSidebar sidebar={sidebar} />
      <div className="container">
        <div className="search_container">
          <div className="input-group">
            <input
              type="text"
              name="search"
              id=""
              placeholder="Filter by orderId or subject"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <button>
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
        <ToastContainer />
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <td>Id</td>
                <td>Paper Details</td>
                <td>Pages</td>
                <td>Words</td>
                <td>Charges(SH)</td>
                <td>Penalties</td>
                <td>Time Assigned</td>
                <td>Deadline</td>
                <td className="due-time">Submitted At</td>
                <td className="due-time">Confirmed At</td>
                <td>
                  <p>Download</p>
                  <p>Instructions</p>
                </td>

                <td>Revised Answers</td>
                <td>Revision Instructions</td>
                <td>Original Answers</td>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.length > 0 ? (
                filteredOrders?.map((order) =>
                  order.completed ? (
                    <tr key={order._id}>
                      <td>
                        {order.orderId ? (
                          <p className="description" style={{}}>
                            {order.orderId}
                          </p>
                        ) : (
                          <p className="description">Order Id: {order._id}</p>
                        )}
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "150px" }}
                        >{`Title: ${order.subject}`}</p>
                        <p
                          className="description"
                          style={{
                            color: "var(--blue)",
                            fontWeight: "600",
                            minWidth: "150px",
                          }}
                        >{`Category: ${order.category}`}</p>
                      </td>
                      <td>
                        <p className="description">{order?.page}</p>
                      </td>
                      <td>
                        <p className="description">{order?.words}</p>
                      </td>
                      <td>
                        <p className="description">{order?.charges}</p>
                      </td>
                      <td>
                        <p className="description">{order?.penalty}</p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "200px" }}
                        >
                          {formatDate(order.assignedAt)}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "200px" }}
                        >
                          {formatDate(order.dateline)}
                        </p>
                        <p className="description">
                          {countdowns[order._id]?.timeString}
                        </p>
                      </td>
                      <td className="due-time">
                        <div className="time-container">
                          <p
                            className="description"
                            style={{ color: "var(--blue)" }}
                          >
                            {formatDate(order?.submittedAt)}
                          </p>
                        </div>
                      </td>
                      <td className="due-time">
                        <div className="time-container">
                          <p
                            className="description"
                            style={{ color: "var(--blue)" }}
                          >
                            {formatDate(order?.completedAt)}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div className="description-buttons">
                          {order?.description && (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleWorkingWork(order);
                                handleDescriptionModal();
                              }}
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order.files.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleDownloadAllFiles(order?.files);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>

                      <td>
                        <div className="description-buttons">
                          {order?.revisionAnswersDescriptions?.length > 0 && (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleRevisionOrder(
                                  order?.revisionAnswersDescriptions
                                );
                              }}
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order?.answerFiles?.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleDownloadAllFiles(order?.answerFiles);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.inRevisionComments && (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleRevisionOrder(order?.inRevisionComments);
                              }}
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order?.inRevisionFiles?.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleDownloadAllFiles(order?.inRevisionFiles);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.originalAnswerDescription && (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleWorkingWork({
                                  subject: order?.subject,
                                  description: order?.originalAnswerDescription,
                                });
                                handleDescriptionModal();
                              }}
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order?.originalAnswers?.length > 0 && (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleDownloadAllFiles(order?.originalAnswers);
                              }}
                            >
                              files
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : null
                )
              ) : (
                <tr>
                  <td colSpan="6">
                    <p>There is no order completed</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div
          className={`submit-orders account modal ${
            submitModal ? "active" : null
          } `}
        >
          <SubmitOrders
            workingOrder={workingOrder}
            handleSubmitModal={handleSubmitModal}
            fetchUserWork={fetchUserWork}
          />
        </div>
        <div
          className={`modal see-work-description ${
            descriptionModal ? "active" : null
          }`}
        >
          <JobDescription
            workingOrder={workingOrder}
            handleDescriptionModal={handleDescriptionModal}
          />
        </div>
      </div>
      {loading && <Preloader />}
      {displayDownloadModal && <DownloadFilesModal />}
      <RevisionHistoryModal />
    </div>
  );
};

export default CompletedOrders;
