import AdminSidebar from "../../components/Admin/AdminSidebar";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import "./Admin.css";
import Config from "../../Config";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import "./write.css";
import ViewBindersModal from "../../components/Admin/ViewBindersModal";
import AssignSingleUser from "../../components/Admin/AssignSingleUser";
import CancelAssigning from "../../components/Admin/CancelAssigning";
import formatDate from "../../utils/FormatDate";
import headers from "../../headers";
import Preloader from "../../Preloader/Preloader";
import { useSelector, useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import CancelWorkModal from "../../components/Admin/CancelWorkModal";
import RemainingTime from "../../utils/RemainingTime";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
const AssignedOrders = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.alerts.loading);
  const [filterText, setFilterText] = useState("");
  const [work, setWork] = useState([]);
  const [sidebar, showSidebar] = useState(false);
  const [countdowns, setCountdowns] = useState({});
  const [academicWriters, setWritersToAssign] = useState(null);
  const [assigningModal, showAssigningModal] = useState(false);
  const [workJob, setWriter] = useState([]);
  const [cancelAssignment, setCancelAssignment] = useState(false);
  const [cancelCredentials, setCancelCredentials] = useState(null);
  const [singleAssigningUser, setSingleAssigningUser] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [workToDelete, setWorkToDelete] = useState(null);

  const { orderId } = useParams();

  const handleCancelModal = () => {
    setCancelModal(!cancelModal);
  };

  const handleAssigningCredentials = (credentials) => {
    setCancelCredentials(credentials);
  };

  const handleCancelAssigningModal = () => {
    setCancelAssignment(!cancelAssignment);
  };

  const handleSingleUserAssignment = () => {
    setSingleAssigningUser(!singleAssigningUser);
  };

  const handleAssigningModal = () => {
    showAssigningModal(!assigningModal);
  };

  const handleWritersToWorkWith = (academicWriters) => {
    setWritersToAssign(academicWriters);
  };

  const handleWorkToDelete = (order) => {
    setWorkToDelete(order);
  };

  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };

  const debouncedFetchWriters = useCallback(
    debounce(() => fetchWriters(), 300),
    [filterText, page]
  );

  const fetchWriters = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(
        `${Config.baseUrl}/api/assignments/all/assigned/assignments?page=${page}&limit=20&search=${filterText}`,
        {
          headers,
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        setWork(response.data.data);
        setTotalPages(response.data.totalPages || 1);
      } else {
        alert("There was a network problem fetching data");
      }
    } catch (error) {
      dispatch(hideLoading());
      alert("There was a problem accessing the server");
    }
  };

  useEffect(() => {
    debouncedFetchWriters();
  }, [filterText, page, debouncedFetchWriters]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = work.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.dateline,
            order.time,
            order.charges,
            order.penalty
          );
          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };
          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [work]);

  useEffect(() => {
    if (orderId && work.length > 0) {
      const element = document.getElementById(`order-${orderId}`);
      setFilterText(orderId);
      if (element) {
        element.style.background = "var(--blue)";
        const tableDescription = element.getElementsByTagName("p");
        for (let i = 0; i < tableDescription.length; i++) {
          tableDescription[i].style.color = "#fff";
        }
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [orderId, work]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      window.scrollTo(0, 0);
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      window.scrollTo(0, 0);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      const buttonClass =
        i === page ? "navigation-btns current-page" : "navigation-btns";
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={buttonClass}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="admin work">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="ASSIGNMENTS IN PROGRESS"
      />
      <AdminSidebar sidebar={sidebar} />
      <p className="empty"></p>
      <div className="container">
        <div className="search_container">
          <div className="input-group">
            <input
              type="text"
              name="search"
              id=""
              placeholder="Filter by orderId or subject"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <button>
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
        {work.length > 0 ? (
          <div className="table_wrapper">
            <table>
              <thead>
                <tr>
                  <td>OrderId</td>
                  <td>Client</td>
                  <td>Writer</td>
                  <td>Paper/Details</td>
                  <td>Uploaded at</td>
                  <td>Assigned At</td>
                  <td>Deadline</td>
                  <td>Work</td>
                  <td>Bid</td>
                  <td>Charges</td>
                  <td>Cancel Order</td>
                </tr>
              </thead>
              <tbody>
                {work?.map((order) => (
                  <tr key={order._id} id={`order-${order.orderId}`}>
                    <td>
                      <p className="description">{order?.orderId}</p>
                    </td>
                    <td>
                      <p className="description" style={{ minWidth: "90px" }}>
                        {order?.clientName}
                      </p>
                    </td>
                    <td>
                      <p className="description" style={{ width: "100px" }}>
                        {` ${order?.assignedTo?.firstName} ${order?.assignedTo?.lastName} `}
                      </p>
                      <p className="description">
                        {`ID: ${order?.assignedTo?.systemId}`}
                      </p>
                    </td>
                    <td>
                      <p
                        className="description"
                        style={{
                          minWidth: "270px",
                          color: "var(--blue)",
                        }}
                      >
                        Subject:{order?.subject}
                      </p>
                      <p
                        className="description"
                        style={{
                          minWidth: "150px",
                        }}
                      >
                        Category:{order?.category}
                      </p>
                    </td>
                    <td>
                      {order.createdAt && (
                        <p
                          className="description"
                          style={{ minWidth: "120px" }}
                        >
                          {formatDate(order?.createdAt)}
                        </p>
                      )}
                    </td>
                    <td>
                      {order.assignedAt && (
                        <p
                          className="description"
                          style={{ minWidth: "120px" }}
                        >
                          {formatDate(order?.assignedAt)}
                        </p>
                      )}
                    </td>
                    <td>
                      <p className="description" style={{ minWidth: "150px" }}>
                        {`${new Date(order.dateline).toLocaleDateString()}  @${
                          order.time
                        }`}
                      </p>
                      <p className="description">
                        {countdowns[order._id]?.timeString}
                      </p>
                    </td>

                    <td>
                      <p className="description">Words:{order?.words}</p>
                      <p className="description">Pages:{order?.page}</p>
                    </td>
                    <td>
                      <p className="description">{`Bids: ${order.bid}`}</p>
                      {order.bid !== 0 && order.assigned ? (
                        <p
                          className="description"
                          style={{ color: "var(--success-color)" }}
                        >
                          Already Assigned
                        </p>
                      ) : null}
                      {order.bid !== 0 && !order.assigned ? (
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleWritersToWorkWith([
                              ...order.writers,
                              { assignmentId: order._id },
                            ]);
                            handleAssigningModal();
                          }}
                        >
                          View
                        </button>
                      ) : null}
                    </td>

                    <td>
                      <p className="description">{order?.charges}</p>
                    </td>

                    <td>
                      <button
                        className="table-btn"
                        onClick={() => {
                          handleWorkToDelete(order);
                          handleCancelModal();
                        }}
                      >
                        CANCEL
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No assignments in progress</p>
        )}
        <div className="pagination" style={{ marginTop: "10px" }}>
          <button
            onClick={handlePrevPage}
            disabled={page === 1}
            className="navigation-btns"
          >
            Prev
          </button>
          {renderPageNumbers()}
          <button
            onClick={handleNextPage}
            disabled={page === totalPages}
            className="navigation-btns"
          >
            Next
          </button>
        </div>
      </div>

      <div
        className={`modal assign-user ${singleAssigningUser ? "active" : null}`}
      >
        <AssignSingleUser
          handleSingleUserAssignment={handleSingleUserAssignment}
          workJob={workJob}
          fetchWriters={fetchWriters}
        />
      </div>
      <div className={`modal view-binders ${assigningModal ? "active" : null}`}>
        <ViewBindersModal
          handleAssigningModal={handleAssigningModal}
          academicWriters={academicWriters}
          fetchWriters={fetchWriters}
        />
      </div>

      <div
        className={`modal cancel-assigning ${
          cancelAssignment ? "active" : null
        }`}
      >
        <CancelAssigning
          cancelCredentials={cancelCredentials}
          handleCancelAssigningModal={handleCancelAssigningModal}
          fetchWriters={fetchWriters}
        />
      </div>

      <div className={`modal cancel-order ${cancelModal ? "active" : null}`}>
        <div className="modal-wrapper">
          <CancelWorkModal
            workToDelete={workToDelete}
            handleCancelModal={handleCancelModal}
            fetchWork={fetchWriters}
          />
        </div>
      </div>
      {loading && <Preloader />}
    </div>
  );
};

export default AssignedOrders;
