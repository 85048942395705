import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displayDownloadModal: false,

  selectedFiles: null,
};

const DownloadFilesSlice = createSlice({
  name: "downloadModal",
  initialState,
  reducers: {
    openDownloadModal(state, action) {
      state.selectedFiles = action.payload;
      state.displayDownloadModal = true;
    },
    closeDownloadModal(state) {
      state.selectedFiles = null;
      state.displayDownloadModal = false;
    },
  },
});

export const { openDownloadModal, closeDownloadModal } =
  DownloadFilesSlice.actions;

export default DownloadFilesSlice;
