import AdminSidebar from "../../components/Admin/AdminSidebar";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import "./AssignedWork.css";
import axios from "axios";
import Config from "../../Config";
import { useEffect, useState } from "react";
import UpdateModal from "../../components/Admin/UpdateModal";
import DeleteWork from "../../components/Admin/DeleteWork";
import JobDescription from "../../components/Admin/JobDescription";
import formatDate from "../../utils/FormatDate";
import headers from "../../headers";
import Preloader from "../../Preloader/Preloader";
import { useSelector, useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import CancelWorkModal from "../../components/Admin/CancelWorkModal";
import DownloadFilesModal from "../../components/DownloadFilesModal";
import { openDownloadModal } from "../../Redux/features/DownloadFilesSlice";
import RemainingTime from "../../utils/RemainingTime";
const AssignedWork = () => {
  const [workToDelete, setWorkToDelete] = useState(null);
  const [deleteModal, showDeleteModal] = useState(false);
  const [sidebar, showSidebar] = useState(false);
  const [orders, setOrders] = useState([]);
  const [updateModal, showUpdateModal] = useState(false);
  const [orderToUpdate, setOrderToUpdate] = useState(null);
  const [page, setPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [countdowns, setCountdowns] = useState({});
  const [filteredOrders, setFilteredOrders] = useState("");
  const [totalJobAmount, setJobTotalAmount] = useState("");
  const [workingOrder, setWorkingOrder] = useState(null);
  const [descriptionModal, showDescriptionModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [cancelModal, setCancelModal] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.alerts.loading);
  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const handleFilesDownload = (files) => {
    dispatch(openDownloadModal(files));
  };
  const handleDeleteModal = () => {
    showDeleteModal(!deleteModal);
  };
  const handleOrderToUpdate = (order) => {
    setOrderToUpdate(order);
  };
  const handleWorkToDelete = (order) => {
    setWorkToDelete(order);
  };
  const handleUpdateModal = () => {
    showUpdateModal(!updateModal);
  };
  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleCancelModal = () => {
    setCancelModal(!cancelModal);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = orders.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.dateline,
            order.time,
            order.charges,
            order.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [orders]);
  const fetchWork = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(
        `${Config.baseUrl}/api/assignments/get/all/assignments?page=${page}&search=${filterText}`,
        {
        headers,
        }
      );
      dispatch(hideLoading());
      setOrders(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log("Internal server error!");
    }
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 0);
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    window.scrollTo(0, 0);
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      const buttonClass =
        i === page ? "navigation-btns current-page" : "navigation-btns";
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={buttonClass}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  useEffect(() => {
    fetchWork();
  }, [filterText, page]);

  useEffect(() => {
    const calculateJobTotalCharges = () => {
      if (!Array.isArray(orders)) {
        setJobTotalAmount(0);
        return;
      }

      const priceList = orders
        .filter((order) => order.completed)
        .map((order) => order.charges)
        .filter((charge) => charge != null);

      const totalAmount = priceList.reduce((a, b) => a + b, 0);

      setJobTotalAmount(totalAmount);
    };

    calculateJobTotalCharges();
  }, [orders]);

  const handleWorkingWork = (order) => {
    setWorkingOrder(order);
  };
  const handleDescriptionModal = () => {
    showDescriptionModal(!descriptionModal);
  };
  useEffect(() => {
    const filtered = orders?.filter((order) => {
      const orderIdMatch =
        order?.orderId?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        "";
      const subjectMatch =
        order?.subject?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        "";
      return orderIdMatch || subjectMatch;
    });
    setFilteredOrders(filtered);
  }, [filterText, orders]);

  return (
    <div className="admin assign">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="ORDERS ADJUSTMENT"
      />
      <AdminSidebar sidebar={sidebar} />
      <p className="empty"></p>
      <div className="container">
        <div className="header">
          <p className="modal-header">ORDERS</p>
          <p className="modal-header">{`TOTAL COMPLETED ORDERS PRICE: ${totalJobAmount}`}</p>
        </div>
        <div className="search_container">
          <div className="input-group">
            <input
              type="text"
              name="search"
              id=""
              placeholder="Filter by orderId or subject"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <button>
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <td>ID</td>
                <td className="date">CLIENT</td>
                <td>CREATED</td>
                <td>DEADLINE</td>
                <td className="date">WRITER</td>
                <td className="date">ASSIGNED</td>
                <td className="date">STATUS</td>
                <td className="date">PAPER DETAILS</td>
                <td>WORK</td>
                <td>CHARGES</td>
                <td>PENALTY</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>DOWNLOAD</p>
                    <p>INSTRUCTIONS</p>
                  </div>
                </td>
                <td className="description">UPDATE</td>
                <td className="description">CANCEL</td>
                <td className="description">DELETE</td>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.length > 0 ? (
                filteredOrders?.map((order) => (
                  <tr key={order._id}>
                    <td className="orderId">
                      <p className="times">
                        <p className="superscript">Revisions</p>
                        <p>{order?.inRevisionCounter}</p>
                      </p>
                      <p className="description">{order?.orderId ?? "N/A"}</p>
                    </td>
                    <td>
                      <p className="description">
                        {order?.clientName ?? "N/A"}
                      </p>
                    </td>
                    <td>
                      <p className="description" style={{ minWidth: "180px" }}>
                        {formatDate(order.createdAt)}
                      </p>
                    </td>
                    <td>
                      <p
                        className="description"
                        style={{
                          color: "var(--success-color)",
                          minWidth: "180px",
                        }}
                      >
                        {`${new Date(order.dateline).toLocaleDateString()} @${
                          order.time
                        }`}
                      </p>
                      <p className="description">
                        {countdowns[order._id]?.timeString}
                      </p>
                    </td>
                    <td>
                      {order.assignedTo ? (
                        <>
                          <p className="description">
                            {`${order?.assignedTo?.firstName} ${order?.assignedTo?.lastName}`}
                          </p>
                          <p className="description">
                            {order?.assignedTo?.systemId}
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            className="description"
                            style={{ color: "var(--success-color)" }}
                          >
                            Not assigned
                          </p>
                        </>
                      )}
                    </td>

                    <td>
                      {order.assignedTo ? (
                        <p
                          className="description"
                          style={{ minWidth: "250px" }}
                        >
                          {formatDate(order.assignedAt)}
                        </p>
                      ) : (
                        <p className="description">Not assigned</p>
                      )}
                    </td>
                    <td>
                      <p
                        className="description"
                        style={{
                          color: "var(--success-color)",
                          fontWeight: "700",
                        }}
                      >
                        {order.inRevision
                          ? "In Revision"
                          : order.inReview
                          ? "In Review"
                          : order.completed
                          ? "Completed"
                          : order.assignedTo
                          ? "In Progress"
                          : "Not assigned"}
                      </p>
                    </td>

                    <td>
                      <p
                        className="description"
                        style={{ color: "var(--blue)", minWidth: "250px" }}
                      >{`Subject: ${order.subject}`}</p>
                      <p className="description">{`Category: ${order.category}`}</p>
                    </td>
                    <td>
                      <p className="description">{`Words:${order.words}`}</p>
                      <p className="description">{`Pages:${order.page}`}</p>
                    </td>
                    <td>
                      <p className="description">{order.charges}</p>
                    </td>
                    <td>
                      <p className="description">{order.penalty}</p>
                    </td>

                    <td>
                      <div className="description-buttons">
                        {order?.description ? (
                          <button
                            className="table-btn"
                            style={{
                              fontSize: "var(--buttons-font)",
                              fontWeight: "var(--buttons-font-weight)",
                              marginTop: "1rem",
                              background: "var(--blue)",
                              border: "2px solid var(--blue)",
                            }}
                            onClick={() => {
                              handleWorkingWork(order);
                              handleDescriptionModal();
                            }}
                          >
                            Description!
                          </button>
                        ) : (
                          <p className="description">No description</p>
                        )}

                        {order?.files.length !== 0 && (
                          <button
                            className="table-btn"
                            style={{
                              fontSize: "var(--buttons-font)",
                              fontWeight: "var(--buttons-font-weight)",
                              background: "var(--success-color)",
                              border: "2px solid var(--success-color)",
                            }}
                            onClick={() => {
                              handleFilesDownload(order?.files);
                            }}
                          >
                            Files
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      <button
                        className="table-btn"
                        onClick={() => {
                          handleUpdateModal();
                          handleOrderToUpdate(order);
                        }}
                        style={{
                          fontSize: "var(--buttons-font)",
                          fontWeight: "var(--buttons-font-weight)",
                        }}
                      >
                        UPDATE
                      </button>
                    </td>

                    <td>
                      {order?.assignedTo ? (
                        <button
                          className="table-btn"
                          style={{
                            fontSize: "var(--buttons-font)",
                            fontWeight: "var(--buttons-font-weight)",
                          }}
                          onClick={() => {
                            handleWorkToDelete(order);
                            handleCancelModal();
                          }}
                        >
                          CANCEL
                        </button>
                      ) : (
                        <p className="description">Not assigned</p>
                      )}
                    </td>
                    <td>
                      <button
                        className="table-btn"
                        style={{
                          fontSize: "var(--buttons-font)",
                          fontWeight: "var(--buttons-font-weight)",
                        }}
                        onClick={() => {
                          handleWorkToDelete(order);
                          handleDeleteModal();
                        }}
                      >
                        DELETE
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11}>
                    {" "}
                    Current there are no orders in the system{" "}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination" style={{ marginTop: "10px" }}>
          <button
            onClick={handlePrevPage}
            disabled={page === 1}
            className="navigation-btns"
          >
            Prev
          </button>
          {renderPageNumbers()}
          <button
            onClick={handleNextPage}
            disabled={page === totalPages}
            className="navigation-btns"
          >
            Next
          </button>
        </div>
      </div>
      <div
        className={`modal delete-work-update ${deleteModal ? "active" : null}`}
      >
        <DeleteWork
          handleDeleteModal={handleDeleteModal}
          fetchWork={fetchWork}
          workToDelete={workToDelete}
        />
      </div>
      <div className={`modal cancel-order ${cancelModal ? "active" : null}`}>
        <div className="modal-wrapper">
          <CancelWorkModal
            workToDelete={workToDelete}
            handleCancelModal={handleCancelModal}
            fetchWork={fetchWork}
          />
        </div>
      </div>

      <div className={`modal update ${updateModal ? "active" : ""}`}>
        <UpdateModal
          orderToUpdate={orderToUpdate}
          handleUpdateModal={handleUpdateModal}
          fetchWork={fetchWork}
        />
      </div>
      <div
        className={`modal see-work-description ${
          descriptionModal ? "active" : null
        }`}
      >
        <JobDescription
          workingOrder={workingOrder}
          handleDescriptionModal={handleDescriptionModal}
        />
      </div>

      {loading && <Preloader />}
      {displayDownloadModal && <DownloadFilesModal />}
    </div>
  );
};

export default AssignedWork;
