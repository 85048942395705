import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displayUserDetailsModal: false,
  userDetails: null,
};

const userDetailsSlice = createSlice({
  name: "userDetailsModal",
  initialState,
  reducers: {
    openDetailsModal(state, action) {
      state.userDetails = action.payload;
      state.displayUserDetailsModal = true;
    },
    closeDetailsModal(state) {
      state.userDetails = null;
      state.displayUserDetailsModal = false;
    },
  },
});

export const { openDetailsModal, closeDetailsModal } = userDetailsSlice.actions;

export default userDetailsSlice;
