import AdminSidebar from "../../components/Admin/AdminSidebar";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Config from "../../Config";
import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./submittedOrders.css";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import { useSelector, useDispatch } from "react-redux";
import Preloader from "../../Preloader/Preloader";
import RequestRevisionModal from "../../components/Admin/RequestRevisionModal";
import JobDescription from "../../components/Admin/JobDescription";
import formatDate from "../../utils/FormatDate";
import headers from "../../headers";

import { openDownloadModal } from "../../Redux/features/DownloadFilesSlice";

import DownloadFilesModal from "../../components/DownloadFilesModal";
import "./CompletedOrders.css";


import RemainingTime from "../../utils/RemainingTime";

import { useParams } from "react-router-dom";

import { openRevisionHistoryModal } from "../../Redux/features/RevisionHistorySlice";

import RevisionHistoryModal from "../../components/RevisionHistoryModal/RevisionHistoryModal";

const AdminCompletedOrders = () => {
  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const handleDownloadAllFiles = (files) => {
    dispatch(openDownloadModal(files));
  };

  const loading = useSelector((state) => state.alerts.loading);
  const dispatch = useDispatch();
  const [sidebar, showSidebar] = useState(false);
  const [orders, setOrders] = useState([]);
  const [workingOrder, setWorkingOrder] = useState([]);
  const [countdowns, setCountdowns] = useState({});
  const [jobDescription, showJobDescription] = useState(false);

  const handleDescriptionModal = () => {
    showJobDescription(!jobDescription);
  };
  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };

  const [requestRevisionModal, setInRequestRevisionModal] = useState(false);

  const cancelInRevision = async (answerId, orderId) => {
    try {
      const response = await axios.put(
        `${Config.baseUrl}/api/answers/${answerId}/${orderId}/cancel/revision`,
        {},
        {
          headers,
        }
      );

      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        fetchData();
      }
    } catch (error) {
      toast.error("There was an error updating the revision status.");
      console.error(`There was an error: ${error}`);
    }
  };

  const fetchData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(`${Config.baseUrl}/api/answers/get`, {
        headers,
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setOrders(response.data.data);
      }
    } catch (error) {
      console.error(`There was an error: ${error}`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = orders.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.assignmentId.dateline,
            order.assignmentId.time,
            order.assignmentId.charges,
            order.assignmentId.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [orders]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const handleInRevisionModal = () => {
    setInRequestRevisionModal(!requestRevisionModal);
  };

  const handleWorkingOrder = (order) => {
    setWorkingOrder(order);
  };

  const getTotalPayable = () => {
    let totalPayable = 0;
    orders.forEach((order) => {
      if (order?.assignmentId?.completed) {
        const charges = parseFloat(order?.assignmentId?.charges) || 0;
        const penalty = parseFloat(order?.assignmentId?.penalty) || 0;
        const payable = charges - penalty;
        totalPayable += payable;
      }
    });
    return totalPayable;
  };

  const { orderId } = useParams();

  useEffect(() => {
    if (orderId && orders.length > 0) {
      const element = document.getElementById(`order-${orderId}`);
      element.style.background = "var(--success-color)";
      const tableDescription = element.getElementsByTagName("p");
      for (let i = 0; i < tableDescription.length; i++) {
        tableDescription[i].style.color = "#fff";
      }
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [orderId, orders]);

  return (
    <div className="admin submitted completed">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="COMPLETED ORDERS"
      />
      <AdminSidebar sidebar={sidebar} />
      <div className="container">
        <p className="empty"></p>
        <p className="large-headers">{`TOTAL PAYABLE CASH:${getTotalPayable()}`}</p>
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <td>Order</td>
                <td>Client</td>
                <td className="date">Writer</td>
                <td className="date">Work</td>
                <td className="date">Page Details</td>
                <td className="date">Created At</td>
                <td className="date">Submitted At</td>
                <td>State</td>
                <td>Charges</td>
                <td>Penalties</td>
                <td>Deadline</td>
                <td>Payable</td>
                <td>Original Instructions</td>
                <td>Revision answers</td>
                <td>Original answers</td>
                <td>InRevision instructions</td>
                <td className="date">Completed At</td>
              </tr>
            </thead>
            <tbody>
              {orders.length === 0 ? (
                <tr>
                  <td colSpan="8">
                    <p className="description">
                      There are no submitted orders!
                    </p>
                  </td>
                </tr>
              ) : (
                orders.map(
                  (order) =>
                    order?.assignmentId?.completed && (
                      <tr
                        key={order._id}
                        id={`order-${order?.assignmentId?.orderId}`}
                      >
                        <td className="orderId">
                          <p className="times">
                            <p className="superscript">Revisions</p>

                            <p>{order?.assignmentId?.inRevisionCounter}</p>
                          </p>
                          <p className="description">
                            {order?.assignmentId
                              ? order?.assignmentId?.orderId
                              : "N/A"}
                          </p>
                        </td>
                        <td>
                          <p className="description date">
                            {order?.assignmentId?.clientName}
                          </p>
                        </td>
                        <td>
                          <p className="description date">
                            {" "}
                            {order?.writerId
                              ? `${order?.writerId?.firstName} ${order?.writerId?.lastName}`
                              : "N/A"}
                          </p>
                          <p className="description">
                            {`ID:${order?.writerId.systemId} `}
                          </p>
                        </td>

                        <td>
                          <p className="description">
                            {`Pages:${order?.assignmentId?.page}`}
                          </p>
                          <p className="description">
                            {`Words:${order?.assignmentId?.words}`}
                          </p>
                        </td>

                        <td>
                          <p
                            className="description date"
                            style={{ color: "var(--blue)", minWidth: "260px" }}
                          >
                            {`Subject:${order?.assignmentId?.subject || "N/A"}`}
                          </p>
                          <p className="description date">
                            {`Category:${
                              order?.assignmentId?.category || "N/A"
                            }`}
                          </p>
                        </td>
                        <td>
                          <p className="description date">
                            {formatDate(order?.createdAt)}
                          </p>
                        </td>
                        <td>
                          {order.submittedAt && (
                            <p className="description date">
                              {formatDate(order?.submittedAt)}
                            </p>
                          )}
                        </td>
                        <td>
                          <p className="description">
                            {order?.category || "N/A"}
                          </p>
                        </td>
                        <td>
                          <p className="description">
                            {order?.assignmentId?.charges || "N/A"}
                          </p>
                        </td>
                        <td>
                          <p className="description">
                            {order?.assignmentId?.penalty}
                          </p>
                        </td>
                        <td>
                          <p
                            className="description"
                            style={{ minWidth: "150px" }}
                          >
                            {order?.assignmentId.dateline.split("T")[0]} at{" "}
                            {order?.assignmentId?.time}
                          </p>
                          <p
                            className="description"
                            style={{ minWidth: "150px" }}
                          >
                            {countdowns[order._id]?.timeString}
                          </p>
                        </td>
                        <td>
                          <p className="description date">
                            {`${order?.assignmentId?.charges} - ${
                              order?.assignmentId.penalty
                            } = ${
                              parseFloat(order?.assignmentId?.charges) -
                              parseFloat(order?.assignmentId?.penalty)
                            }`}
                          </p>
                        </td>
                        <td>
                          {order?.assignmentId?.files?.length !== 0 && (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  order?.assignmentId?.files
                                )
                              }
                            >
                              Files
                            </button>
                          )}
                          {order?.assignmentId?.description && (
                            <button
                              className="table-btn"
                              style={{
                                marginTop: "1rem",
                                background: "var(--blue)",
                                border: "2px solid var(--white)",
                              }}
                              onClick={() => {
                                handleDescriptionModal();
                                handleWorkingOrder({
                                  subject: order?.assignmentId?.subject,
                                  description: order?.assignmentId?.description,
                                });
                              }}
                            >
                              Description
                            </button>
                          )}
                        </td>
                        <td>
                          {order?.assignmentId?.answerFiles?.length !== 0 && (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  order?.assignmentId?.answerFiles
                                )
                              }
                            >
                              Files
                            </button>
                          )}
                          {order?.assignmentId?.revisionAnswersDescriptions
                            ?.length > 0 && (
                            <button
                              className="table-btn"
                              style={{
                                marginTop: "1rem",
                                background: "var(--blue)",
                                border: "2px solid var(--white)",
                              }}
                              onClick={() => {
                                handleRevisionOrder(
                                  order?.assignmentId
                                    ?.revisionAnswersDescriptions
                                );
                              }}
                            >
                              Description
                            </button>
                          )}
                        </td>
                        <td>
                          {order?.assignmentId?.originalAnswers?.length !==
                            0 && (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  order?.assignmentId?.originalAnswers
                                )
                              }
                            >
                              Files
                            </button>
                          )}
                          {order?.assignmentId?.originalAnswerDescription && (
                            <button
                              className="table-btn"
                              style={{
                                marginTop: "1rem",
                                background: "var(--blue)",
                                border: "2px solid var(--white)",
                              }}
                              onClick={() => {
                                handleDescriptionModal();
                                handleWorkingOrder({
                                  subject: order?.assignmentId?.subject,
                                  description:
                                    order?.assignmentId
                                      ?.originalAnswerDescription,
                                });
                              }}
                            >
                              Description
                            </button>
                          )}
                        </td>
                        <td>
                          {order?.inRevision ? (
                            <button
                              className="table-btn"
                              onClick={() =>
                                cancelInRevision(
                                  order?._id,
                                  order?.assignmentId?._id
                                )
                              }
                            >
                              Cancel
                            </button>
                          ) : (
                            <>
                              <button
                                className="table-btn setInRevision"
                                onClick={() => {
                                  handleWorkingOrder({
                                    answerId: order?._id,
                                    email: order?.writerId?.email || "",
                                    orderId: order?.assignmentId?._id || "",
                                    subject: order?.assignmentId?.subject || "",
                                    dateline:
                                      order?.assignmentId?.dateline || "",
                                    time: order?.assignmentId?.time || "",
                                  });
                                  handleInRevisionModal();
                                }}
                                style={{
                                  border: "2px solid var(--success-color)",
                                }}
                              >
                                Request
                              </button>
                              {order?.assignmentId?.inRevisionFiles?.length >
                                0 && (
                                <button
                                  className="table-btn"
                                  style={{ marginTop: "6px" }}
                                  onClick={() =>
                                    handleDownloadAllFiles(
                                      order?.assignmentId?.inRevisionFiles
                                    )
                                  }
                                >
                                  Files
                                </button>
                              )}
                              {order?.assignmentId?.inRevisionComments && (
                                <button
                                  className="table-btn"
                                  style={{
                                    background: "var(--blue)",
                                    border: "2px solid var(--blue)",
                                    marginTop: "5px",
                                  }}
                                  onClick={() => {
                                    handleRevisionOrder(
                                      order?.assignmentId?.inRevisionComments
                                    );
                                  }}
                                >
                                  comments
                                </button>
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {order?.assignmentId?.completed && (
                            <p className="description date">
                              {formatDate(order?.assignmentId?.completedAt)}
                            </p>
                          )}
                        </td>
                      </tr>
                    )
                )
              )}
            </tbody>
          </table>
        </div>
        <div
          className={`modal request-revision ${
            requestRevisionModal ? "active" : null
          }`}
        >
          <RequestRevisionModal
            handleInRevisionModal={handleInRevisionModal}
            workingOrder={workingOrder}
            fetchData={fetchData}
          />
        </div>
        <div
          className={`modal description-modal ${
            jobDescription ? "active" : null
          }`}
        >
          <JobDescription
            handleDescriptionModal={handleDescriptionModal}
            workingOrder={workingOrder}
          />
        </div>
      </div>
      {loading && <Preloader />}

      {displayDownloadModal && <DownloadFilesModal />}
      <RevisionHistoryModal />
    </div>
  );
};

export default AdminCompletedOrders;
