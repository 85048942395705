import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../../Config";
import AccountNavbar from "../../../components/account/AccountNavbar/AccountNavbar";
import AccountSidebar from "../../../components/account/AccountSidebar/AccountSidebar";
import BindingModal from "./BindingModal";
import ".././Clients.css";
import "./GetOrders.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import formatDate from "../../../utils/FormatDate";
import JobDescription from "../../../components/Admin/JobDescription";

import headers from "../../../headers";

import Preloader from "../../../Preloader/Preloader";

import { showLoading, hideLoading } from "../../../Redux/features/AlertSlice";

import { useSelector, useDispatch } from "react-redux";

import { openDownloadModal } from "../../../Redux/features/DownloadFilesSlice";

import DownloadFilesModal from "../../../components/DownloadFilesModal";

const GetOrders = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [bindModal, showBindModal] = useState(false);
  const [work, setWork] = useState([]);
  const [sidebar, showSidebar] = useState(false);
  const [workingOrder, setWorkingOrder] = useState(null);
  const [job, setJob] = useState(null);
  const [countdowns, setCountdowns] = useState({});
  const [descriptionModal, showDescriptionModal] = useState(false);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.alerts.loading);

  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const handleShowBindModal = () => {
    showBindModal(!bindModal);
  };

  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };

  const handleOrderToBind = (job) => {
    setJob(job);
  };

  const handleDownloadAllFiles = (order) => {
    dispatch(openDownloadModal(order));
  };

  const fetchWriters = async () => {
    try {
      dispatch(showLoading());

      const response = await axios.get(
        `${Config.baseUrl}/api/assignments/get/unassigned?page=${page}&limit=20`,
        {
          headers,
        }
      );

      dispatch(hideLoading());

      if (response.data.success) {
        setWork(response.data.data || []);
        setTotalPages(response.data.totalPages || 1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const calculateRemainingTime = (dueDate, time, charges, penalty) => {
    const now = new Date().getTime();
    const dueDateTimeString = `${dueDate.split("T")[0]}T${time}`;
    const dueDateTime = new Date(dueDateTimeString).getTime();

    if (isNaN(dueDateTime)) {
      return { timeString: "Invalid date/time", overdue: false, penalty: 0 };
    }

    const distance = dueDateTime - now;
    const overdue = distance < 0;
    const absDistance = Math.abs(distance);

    const days = Math.floor(absDistance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (absDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((absDistance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((absDistance % (1000 * 60)) / 1000);

    if (overdue && penalty >= charges) {
      return {
        timeString: "The penalty has equaled the charge of the job",
        overdue: false,
        minutes: 0,
      };
    }

    const timeString = overdue ? (
      <p className="description" style={{ color: "var(--pinkRed)" }}>
        Expired {days}d {hours}h {minutes}m {seconds}s ago
      </p>
    ) : (
      <p className="description" style={{ color: "var(--success-color)" }}>
        {days}d {hours}h {minutes}m {seconds}s
      </p>
    );

    return {
      timeString,
      overdue,
      minutes: Math.floor(absDistance / (1000 * 60)),
    };
  };

  useEffect(() => {
    fetchWriters();
  }, [page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 0);
    setPage((prevPage) => prevPage - 1);
  };
  const handleNextPage = () => {
    window.scrollTo(0, 0);
    setPage((prevPage) => prevPage + 1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      const buttonClass =
        i === page ? "navigation-btns current-page" : "navigation-btns";
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={buttonClass}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = work.reduce((acc, order) => {
          const { timeString, overdue, minutes } = calculateRemainingTime(
            order.dateline,
            order.time,
            order.charges,
            order.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [work]);

  const handleWorkingWork = (order) => {
    setWorkingOrder(order);
  };

  const checkIfUserHasBid = (order) => {
    if (!sessionStorage.getItem("userId") || !order.writers) {
      return false;
    }
    return order.writers.some(
      (writer) => writer._id === sessionStorage.getItem("userId")
    );
  };

  const handleDescriptionModal = () => {
    showDescriptionModal(!descriptionModal);
  };

  return (
    <>
      <div className="orders account">
        <AccountNavbar
          sidebar={sidebar}
          handleShowSidebar={handleShowSidebar}
          title="GET ORDERS"
        />
        <AccountSidebar sidebar={sidebar} />
        <div className="container">
          <p className="empty"></p>
          <p className="medium-header" style={{ color: "var(--blue)" }}>
            Once you have placed a bid and the company has assigned you the
            work,
            <br />
            The order will reflect In progress page!
            <br />
            Kindly submit the work on time! Any delay will cost you 5 shillings
            per minute
          </p>
          {work.length > 0 && (
            <div className="table_wrapper">
              <table>
                <thead>
                  <tr>
                    <td>Id</td>
                    <td>Paper Details</td>
                    <td>Uploaded At</td>
                    <td>Deadline</td>
                    <td>Pages</td>
                    <td>Words</td>
                    <td>Charge</td>
                    <td>
                      <p>Download</p>
                      <p>Instructions</p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {work.map(
                    (order) =>
                      !checkIfUserHasBid(order) && (
                        <tr key={order._id}>
                          <td>
                            <p className="description">{order.subject}</p>
                            {order.orderId ? (
                              <p className="description">{`${order.orderId}`}</p>
                            ) : (
                              <p className="description">{`${order._id}`}</p>
                            )}
                          </td>
                          <td>
                            <p className="description">{order.category}</p>
                            <p className="description">
                              <button
                                disabled={countdowns[order._id]?.overdue}
                                className="table-btn"
                                style={
                                  countdowns[order._id]?.overdue
                                    ? {
                                        backgroundColor: "green",
                                        color: "white",
                                        border: "2px solid green",
                                        fontSize:
                                          "var(--table-description-font)",
                                      }
                                    : {
                                        fontSize:
                                          "var(--table-description-font)",
                                      }
                                }
                                onClick={() => {
                                  handleShowBindModal();
                                  handleOrderToBind(order);
                                }}
                              >
                                {countdowns[order._id]?.overdue
                                  ? "EXPIRED"
                                  : "BID"}
                              </button>
                            </p>
                          </td>
                          <td>
                            <p className="description">
                              {formatDate(order.createdAt)}
                            </p>
                          </td>

                          <td>
                            <div className="time-container">
                              <p className="description">
                                Submit by: {order.dateline.split("T")[0]} at{" "}
                                {order.time}
                              </p>
                              {countdowns[order._id]?.overdue ? (
                                <p
                                  className="description"
                                  style={{ color: "var(--pinkRed)" }}
                                >
                                  Expired
                                </p>
                              ) : (
                                <p className="description">
                                  {countdowns[order._id]?.timeString}
                                </p>
                              )}
                              <p className="description">Time: {order.time}</p>
                            </div>
                          </td>
                          <td>
                            <p className="description descriptive-text">
                              {order.page}
                            </p>
                          </td>
                          <td>
                            <p className="description descriptive-text">
                              {order.words}
                            </p>
                          </td>

                          <td>
                            <p className="description descriptive-text">{`Sh.${order.charges}`}</p>
                          </td>
                          <td>
                            <div className="navigation-buttons">
                              {order?.description && (
                                <button
                                  className="table-btn"
                                  style={{
                                    background: "var(--blue)",
                                    border: "2px solid var(--blue)",
                                  }}
                                  onClick={() => {
                                    handleWorkingWork(order);
                                    handleDescriptionModal();
                                  }}
                                >
                                  Description
                                </button>
                              )}

                              {order.files.length !== 0 ? (
                                <button
                                  className="table-btn"
                                  onClick={() => {
                                    handleDownloadAllFiles(order.files);
                                  }}
                                >
                                  Get files
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          )}
          <div className="pagination" style={{ marginTop: "10px" }}>
            <button
              onClick={handlePrevPage}
              disabled={page === 1}
              className="navigation-btns"
            >
              Prev
            </button>
            {renderPageNumbers()}
            <button
              onClick={handleNextPage}
              disabled={page === totalPages}
              className="navigation-btns"
            >
              Next
            </button>
          </div>
          {loading && <Preloader />}
          <div
            className={`modal get-orders ${descriptionModal ? "active" : null}`}
          >
            <JobDescription
              workingOrder={workingOrder}
              handleDescriptionModal={handleDescriptionModal}
            />
          </div>
        </div>
        <BindingModal
          handleShowBindModal={handleShowBindModal}
          bindModal={bindModal}
          job={job}
          fetchWriters={fetchWriters}
        />
      </div>
      {displayDownloadModal && <DownloadFilesModal />}
      <ToastContainer />
    </>
  );
};

export default GetOrders;
