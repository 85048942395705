import { configureStore } from "@reduxjs/toolkit";

import AlertSlice from "./features/AlertSlice";

import AuthSlice from "./features/AuthSlice";

import UserSlice from "./features/UserSlice";

import DownloadFilesSlice from "./features/DownloadFilesSlice";

import CommentSlice from "./features/CommentSlice";

import userDetailsSlice from "./features/UserDetailsSlice";

import RevisionHistorySlice from "./features/RevisionHistorySlice";

export default configureStore({
  reducer: {
    alerts: AlertSlice.reducer,
    auth: AuthSlice.reducer,
    user: UserSlice.reducer,
    downloadModal: DownloadFilesSlice.reducer,
    comment: CommentSlice.reducer,
    userDetailsModal: userDetailsSlice.reducer,
    revisionHistory: RevisionHistorySlice.reducer,
  },
});
