import Config from "../../Config";
import headers from "../../headers";
import axios from "axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateRoles = ({ showUpdatesRoleModel }) => {
  const data = useSelector((state) => state.userDetailsModal.userDetails);
  const [selectedRole, setSelectedRole] = useState("--SELECT ROLE--");
  const [isLoading, setIsLoading] = useState(false);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const updateRole = async (action) => {
    try {
      if (selectedRole !== "editor" && selectedRole !== "admin") {
        toast.error("Please select a valid role");
        return;
      }

      setIsLoading(true);

      const response = await axios.put(
        `${Config.baseUrl}/api/writers/roles/update/${data._id}`,
        {
          role: selectedRole,
          make: action,
        },
        {
          headers,
        }
      );

      console.log(response.data);

      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error("Failed to update the role");
      }
    } catch (error) {
      toast.error("There was an error in updating the role");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal update-roles">
      <ToastContainer />
      <div className="modal-wrapper">
        <button className="close-icon" onClick={showUpdatesRoleModel}>
          &times;
        </button>
        <p className="large-headers">UPDATE ROLES</p>
        <div className="roles_container">
          <select name="role" value={selectedRole} onChange={handleRoleChange}>
            <option value="--SELECT ROLE--">--SELECT ROLE--</option>
            <option value="editor">Editor</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div className="role-buttons">
          <button
            className="table-btn"
            onClick={() => updateRole("remove")}
            disabled={selectedRole === "--SELECT ROLE--" || isLoading}
          >
            {isLoading ? "Processing..." : "REMOVE"}
          </button>
          <button
            className="table-btn"
            onClick={() => updateRole("make")}
            disabled={selectedRole === "--SELECT ROLE--" || isLoading}
          >
            {isLoading ? "Processing..." : "ADD"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateRoles;
