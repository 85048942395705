const RemainingTime = (dueDate, time, charges, penalty) => {
  const now = new Date().getTime();
  const dueDateTimeString = `${dueDate.split("T")[0]}T${time}`;
  const dueDateTime = new Date(dueDateTimeString).getTime();

  if (isNaN(dueDateTime)) {
    return { timeString: "Invalid date/time", overdue: false, penalty: 0 };
  }

  const distance = dueDateTime - now;
  const overdue = distance < 0;
  const absDistance = Math.abs(distance);

  const days = Math.floor(absDistance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (absDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((absDistance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((absDistance % (1000 * 60)) / 1000);

  if (overdue && penalty >= charges) {
    return {
      timeString: "The penalty has equaled the charge of the job",
      overdue: false,
      minutes: 0,
    };
  }

  const timeString = overdue ? (
    <p className="description" style={{ color: "var(--pinkRed)" }}>
      Expired
    </p>
  ) : (
    <p className="description" style={{ color: "var(--success-color)" }}>
      {days}d {hours}h {minutes}m {seconds}s
    </p>
  );
  // const timeString = overdue ? (
  //   <p className="description" style={{ color: "var(--pinkRed)" }}>
  //     Expired {days}d {hours}h {minutes}m {seconds}s ago
  //   </p>
  // ) : (
  //   <p className="description" style={{ color: "var(--success-color)" }}>
  //     {days}d {hours}h {minutes}m {seconds}s
  //   </p>
  // );

  return {
    timeString,
    overdue,
    minutes: Math.floor(absDistance / (1000 * 60)),
  };
};

export default RemainingTime;
