import React from "react";
import "./CommentModal.css";
import { closeCommentModal } from "../../Redux/features/CommentSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const CommentsModal = () => {
  const dispatch = useDispatch();

  const displayCommentModal = useSelector(
    (state) => state.comment.displayCommentModal
  );

  const data = useSelector((state) => state.comment.data) || {};

  const handleCommentSlice = () => {
    dispatch(closeCommentModal());
  };

  return (
    <div className={`modal comments ${displayCommentModal ? "active" : ""}`}>
      <div className="modal-wrapper">
        <div className="modal-wrapper-container">
          <button className="button close-icon" onClick={handleCommentSlice}>
            <p>&times;</p>
          </button>

          {data.comment ? (
            <ReactMarkdown
              children={data.comment}
              remarkPlugins={[remarkGfm]}
            />
          ) : (
            <p>No comment available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentsModal;
