import "./InRevisionModal.css";

import { useDispatch, useSelector } from "react-redux";

import { closeRevisionHistoryModal } from "../../Redux/features/RevisionHistorySlice";

import formatDate from "../../utils/FormatDate";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const RevisionHistoryModal = () => {
  const dispatch = useDispatch();

  const displayHistoryModal = useSelector(
    (state) => state.revisionHistory.displayHistoryModal
  );

  const closeRevisionModal = () => {
    dispatch(closeRevisionHistoryModal());
  };

  const data = useSelector((state) => state.revisionHistory.data);

  return (
    <div
      className={`modal inrevision-modal ${
        displayHistoryModal ? "active" : ""
      }`}
    >
      <div className="modal-wrapper">
        <button className="button close-icon" onClick={closeRevisionModal}>
          <p>&times;</p>
        </button>
        <div className={`modal-wrapper-container`}>
          <p className="large-headers">HISTORY</p>
          <div className="histories">
            {data?.map((comment) => (
              <div className="history-card">
                <p className="date">{formatDate(comment?.date)}</p>
                <ReactMarkdown
                  children={comment?.comment || comment?.description}
                  remarkPlugins={[remarkGfm]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevisionHistoryModal;
