import "./descriptionModal.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
const JobDescription = ({ workingOrder, handleDescriptionModal }) => {
  const subject = workingOrder?.subject?.toUpperCase() || "NO SUBJECT";
  const description = workingOrder?.description || "No description available.";
  return (
    <div className="modal-wrapper job">
      <div className="modal-wrapper-container">
        <p className="modals-header">{`${subject} DESCRIPTION`}</p>
        <ReactMarkdown children={description} remarkPlugins={[remarkGfm]} />
      </div>
      <button className="table-btn" onClick={handleDescriptionModal}>
        CANCEL
      </button>
    </div>
  );
};

export default JobDescription;
