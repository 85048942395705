import React, { useState, useEffect } from "react";
import axios from "axios";
import AccountNavbar from "../../../components/account/AccountNavbar/AccountNavbar";
import AccountSidebar from "../../../components/account/AccountSidebar/AccountSidebar";
import Config from "../../../Config";
import "./InRevision.css";
import "../Clients.css";
import SubmitOrders from "../SubmitOrders/SubmitOrders";
import formatDate from "../../../utils/FormatDate";
import JobDescription from "../../../components/Admin/JobDescription";
import headers from "../../../headers";
import { useDispatch, useSelector } from "react-redux";
import { openDownloadModal } from "../../../Redux/features/DownloadFilesSlice";
import DownloadFilesModal from "../../../components/DownloadFilesModal";
import CommentsModal from "../../../components/CommentsModal/CommentsModal";
import calculateRemainingTime from "../../../utils/CalculateRemainingTime";
import { openCommentModal } from "../../../Redux/features/CommentSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RevisionHistoryModal from "../../../components/RevisionHistoryModal/RevisionHistoryModal";

import { openRevisionHistoryModal } from "../../../Redux/features/RevisionHistorySlice";

const InRevision = () => {
  const [sidebar, setSidebar] = useState(false);
  const [descriptionModal, showDescriptionModal] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [workingOrder, setWorkingOrder] = useState(null);
  const [submitModal, setSubmitModal] = useState(false);
  const [countdowns, setCountdowns] = useState({});

  const dispatch = useDispatch();

  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );

  const showCommentModal = (order) => {
    dispatch(openCommentModal(order));
  };

  const handleWorkingOrder = (order) => {
    setWorkingOrder(order);
  };

  const handleDownloadAllFiles = (files) => {
    dispatch(openDownloadModal(files));
  };

  const toggleSubmitModal = () => {
    setSubmitModal(!submitModal);
  };

  const handleDescriptionModal = () => {
    showDescriptionModal(!descriptionModal);
  };

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };

  const updatePenalty = async (
    assignmentId,
    delayMinutes,
    charges,
    penalty
  ) => {
    try {
      const penaltyAmount = delayMinutes * 5;

      if (penaltyAmount > charges) {
        return;
      }

      const response = await axios.put(
        `${Config.baseUrl}/api/assignments/${assignmentId}/update/penalty`,
        { penalties: penaltyAmount },
        {
          headers,
        }
      );

      if (response.data.success) {
        fetchUserWork();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("An error occurred while updating penalty", error);
      toast.error("Failed to update penalty. Please try again.");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = assignments.reduce((acc, order) => {
          const { timeString, overdue, delayMinutes, penalty } =
            calculateRemainingTime(
              order.assignmentId.dateline,
              order.assignmentId.time,
              order.assignmentId._id,
              order.assignmentId.charges,
              order.assignmentId.penalty
            );

          acc[order.assignmentId._id] = {
            timeString,
            overdue,
            delayMinutes,
            penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [assignments]);

  useEffect(() => {
    const penaltyInterval = setInterval(() => {
      setAssignments((prevAssignments) =>
        prevAssignments.map((order) => {
          const { overdue, delayMinutes } = calculateRemainingTime(
            order.assignmentId.dateline,
            order.assignmentId.time,
            order.assignmentId._id,
            order.assignmentId.charges,
            order.assignmentId.penalty
          );

          if (overdue && !order.penaltyUpdated) {
            updatePenalty(
              order.assignmentId._id,
              delayMinutes,
              order.assignmentId.charges
            );
            return { ...order, penaltyUpdated: true };
          }
          return order;
        })
      );
    }, 60000);

    return () => clearInterval(penaltyInterval);
  }, [assignments]);

  const fetchUserWork = async () => {
    try {
      const response = await axios.get(
        `${Config.baseUrl}/api/answers/${sessionStorage.getItem(
          "userId"
        )}/work/in/revision`,
        { headers }
      );
      setAssignments(response.data.data);
    } catch (error) {
      console.error("Error fetching assignments in revision:", error);
      toast.error("Failed to fetch assignments in revision. Please try again.");
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("userId")) {
      fetchUserWork();
    }
  }, []);
  return (
    <>
      <div className="orders account revision">
        <AccountNavbar
          sidebar={sidebar}
          handleShowSidebar={toggleSidebar}
          title="IN REVISION"
        />
        <p className="empty"></p>

        <p className="medium-header">IN REVISION</p>
        <AccountSidebar sidebar={sidebar} />
        <div className="container">
          <p>
            Once you've submitted the work and if it coincides with the client's
            specifications, it will be completed. If there is any penalty it
            will be deducted from the cost of the work!
            <br />
            If the work is not up to standards the client will require you to
            carry out revision! Meanwhile, total charges - any penalties will be
            your compensation after the client has presided over the work with
            the utmost degree of satisfaction!
          </p>
          <div className="table_wrapper">
            <table>
              <thead>
                <tr>
                  <td>Id</td>
                  <td>Work</td>
                  <td>Charges</td>
                  <td>Subject</td>
                  <td>Requested On</td>
                  <td>Deadline</td>
                  <td>Penalty</td>
                  <td>Revision Instructions</td>
                  <td>Original Instructions</td>
                  <td>Revised Answers</td>
                  <td>Original Answers</td>
                  <td>Submit</td>
                </tr>
              </thead>
              <tbody>
                {assignments.length > 0 ? (
                  assignments.map((assignment) => (
                    <tr key={assignment._id}>
                      <td className="orderId">
                        <p className="times">
                          <p className="superscript">Revisions</p>
                          <p>
                            {assignment?.assignmentId?.inRevisionCounter}
                          </p>{" "}
                        </p>
                        <p className="description">
                          {assignment.assignmentId.orderId}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          Page:{assignment.assignmentId.page}
                        </p>
                        <p className="description">
                          Words:{assignment.assignmentId.words}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {assignment.assignmentId.charges}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "170px" }}
                        >
                          {assignment.assignmentId.subject}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "170px" }}
                        >
                          {formatDate(
                            assignment?.assignmentId.inRevisionRequestedAt
                          )}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ minWidth: "170px" }}
                        >
                          {`${new Date(
                            assignment.assignmentId.dateline
                          ).toLocaleDateString()} at ${
                            assignment.assignmentId.time
                          }`}
                        </p>
                        <p className="description">
                          {countdowns[assignment.assignmentId._id]?.timeString}
                        </p>
                      </td>
                      <td>
                        <p
                          className="description"
                          style={{ color: "var(--pinkRed)" }}
                        >
                          {countdowns[assignment.assignmentId._id]?.penalty > 0
                            ? countdowns[assignment.assignmentId._id]?.penalty
                            : "0"}
                        </p>
                      </td>

                      <td>
                        {assignment.assignmentId.inRevisionFiles.length > 0 ? (
                          <button
                            className="table-btn"
                            onClick={() =>
                              handleDownloadAllFiles(
                                assignment.assignmentId.inRevisionFiles
                              )
                            }
                          >
                            Files
                          </button>
                        ) : (
                          <p className="description">No Files</p>
                        )}
                        <button
                          className="table-btn"
                          style={{
                            marginTop: "5px",
                            background: "var(--blue)",
                            border: "2px solid var(--blue)",
                          }}
                          onClick={() => {
                            handleRevisionOrder(
                              assignment?.assignmentId?.inRevisionComments
                            );
                          }}
                        >
                          view
                        </button>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {assignment.assignmentId.files.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  assignment.assignmentId.files
                                )
                              }
                            >
                              Files
                            </button>
                          ) : (
                            <p>No files</p>
                          )}
                          {assignment?.assignmentId?.description?.length > 1 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleWorkingOrder({
                                  subject:
                                    assignment?.assignmentId?.subject ||
                                    "NO SUBJECT",
                                  description:
                                    assignment?.assignmentId?.description ||
                                    "NO DESCRIPTION",
                                });

                                handleDescriptionModal();
                              }}
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                            >
                              Description
                            </button>
                          ) : (
                            <p>NO DESCRIPTION</p>
                          )}
                        </div>
                      </td>
                      <td>
                        {assignment?.assignmentId?.revisionAnswersDescriptions
                          ?.length > 0 ? (
                          <button
                            className="table-btn"
                            onClick={() => {
                              handleRevisionOrder(
                                assignment?.assignmentId
                                  ?.revisionAnswersDescriptions
                              );
                            }}
                            style={{
                              background: "var(--blue)",
                              border: "2px solid var(--blue)",
                            }}
                          >
                            Description
                          </button>
                        ) : (
                          <p>NO DESCRIPTION</p>
                        )}
                        {assignment?.assignmentId?.answerFiles?.length === 0 ? (
                          <p>No files</p>
                        ) : (
                          <button
                            className="table-btn"
                            style={{ marginTop: "10px" }}
                            onClick={() =>
                              handleDownloadAllFiles(
                                assignment?.assignmentId?.answerFiles
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                      </td>
                      <td>
                        {assignment?.assignmentId?.originalAnswerDescription
                          ?.length > 1 ? (
                          <button
                            className="table-btn"
                            onClick={() => {
                              handleWorkingOrder({
                                subject:
                                  assignment?.assignmentId?.subject ||
                                  "NO SUBJECT",
                                description:
                                  assignment?.assignmentId
                                    ?.originalAnswerDescription ||
                                  "NO DESCRIPTION",
                              });

                              handleDescriptionModal();
                            }}
                            style={{
                              background: "var(--blue)",
                              border: "2px solid var(--blue)",
                            }}
                          >
                            Description
                          </button>
                        ) : (
                          <p>NO DESCRIPTION</p>
                        )}
                        {assignment?.assignmentId?.originalAnswers?.length ===
                        0 ? (
                          <p>No files</p>
                        ) : (
                          <button
                            className="table-btn"
                            style={{ marginTop: "10px" }}
                            onClick={() =>
                              handleDownloadAllFiles(
                                assignment?.assignmentId?.originalAnswers
                              )
                            }
                          >
                            Files
                          </button>
                        )}
                      </td>
                      <td>
                        <button
                          className="table-btn"
                          onClick={() => {
                            handleWorkingOrder({
                              subject: assignment.assignmentId.subject,
                              _id: assignment.assignmentId._id,
                              penalty:
                                countdowns[assignment.assignmentId._id]
                                  ?.penalty > 0
                                  ? countdowns[assignment.assignmentId._id]
                                      ?.penalty
                                  : assignment.assignmentId.penalty,
                            });
                            toggleSubmitModal();
                          }}
                        >
                          SUBMIT
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No assignments in revision</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className={`submit-orders account modal ${
              submitModal ? "active" : ""
            }`}
          >
            <SubmitOrders
              handleSubmitModal={toggleSubmitModal}
              workingOrder={workingOrder}
              fetchUserWork={fetchUserWork}
            />
          </div>

          <div className={`modal job ${descriptionModal ? "active" : null}`}>
            <JobDescription
              workingOrder={workingOrder}
              handleDescriptionModal={handleDescriptionModal}
            />
          </div>
        </div>
      </div>
      {displayDownloadModal && <DownloadFilesModal />}
      <CommentsModal />
      <RevisionHistoryModal />
    </>
  );
};

export default InRevision;
