import AdminSidebar from "../../components/Admin/AdminSidebar";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Config from "../../Config";
import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./submittedOrders.css";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import { useSelector, useDispatch } from "react-redux";
import Preloader from "../../Preloader/Preloader";
import RequestRevisionModal from "../../components/Admin/RequestRevisionModal";
import JobDescription from "../../components/Admin/JobDescription";
import { openDownloadModal } from "../../Redux/features/DownloadFilesSlice";
import DownloadFilesModal from "../../components/DownloadFilesModal";
import formatDate from "../../utils/FormatDate";
import headers from "../../headers";
import { openCommentModal } from "../../Redux/features/CommentSlice";
import CommentsModal from "../../components/CommentsModal/CommentsModal";
import RemainingTime from "../../utils/RemainingTime";

import { useParams } from "react-router-dom";

import { openRevisionHistoryModal } from "../../Redux/features/RevisionHistorySlice";

import RevisionHistoryModal from "../../components/RevisionHistoryModal/RevisionHistoryModal";

const AdminOrdersInRevision = () => {
  const loading = useSelector((state) => state.alerts.loading);
  const [countdowns, setCountdowns] = useState({});
  const dispatch = useDispatch();
  const [sidebar, showSidebar] = useState(false);
  const [orders, setOrders] = useState([]);
  const [workingOrder, setWorkingOrder] = useState([]);

  const [jobDescription, showJobDescription] = useState(false);

  const [filterText, setFilterText] = useState("");

  const [filteredOrders, setFilteredOrders] = useState("");

  const handleDescriptionModal = () => {
    showJobDescription(!jobDescription);
  };
  const showCommentModal = (order) => {
    dispatch(openCommentModal(order));
  };
  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const handleDownloadAllFiles = (files) => {
    dispatch(openDownloadModal(files));
  };

  const [requestRevisionModal, setInRequestRevisionModal] = useState(false);

  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };

  const cancelInRevision = async (answerId, orderId) => {
    try {
      const response = await axios.put(
        `${Config.baseUrl}/api/answers/${answerId}/${orderId}/cancel/revision`,
        {},
        {
          headers,
        }
      );

      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        fetchData();
      }
    } catch (error) {
      toast.error("There was an error updating the revision status.");
      console.error(`There was an error: ${error}`);
    }
  };

  const fetchData = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(`${Config.baseUrl}/api/answers/get`, {
        headers,
      });
      dispatch(hideLoading());
      if (response.data.success) {
        setOrders(response.data.data);
      }
    } catch (error) {
      console.error(`There was an error: ${error}`);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns((prevCountdowns) => {
        const updatedCountdowns = orders.reduce((acc, order) => {
          const { timeString, overdue, minutes } = RemainingTime(
            order.assignmentId.dateline,
            order.assignmentId.time,
            order.assignmentId.charges,
            order.assignmentId.penalty
          );

          acc[order._id] = {
            timeString,
            overdue,
            minutes,
            penalty: order.penalty,
          };

          return acc;
        }, {});
        return updatedCountdowns;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [orders]);

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const filtered = orders?.filter((order) => {
      const orderIdMatch =
        order?.orderId?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        "";
      const subjectMatch =
        order?.subject?.toLowerCase()?.includes(filterText?.toLowerCase()) ||
        "";
      return orderIdMatch || subjectMatch;
    });
    setFilteredOrders(filtered);
  }, [filterText, orders]);
  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const handleInRevisionModal = () => {
    setInRequestRevisionModal(!requestRevisionModal);
  };

  const handleWorkingOrder = (order) => {
    setWorkingOrder(order);
  };

  const { orderId } = useParams();

  useEffect(() => {
    if (orderId && orders.length > 0) {
      const element = document.getElementById(`order-${orderId}`);
      element.style.background = "var(--success-color)";
      const tableDescription = element.getElementsByTagName("p");
      for (let i = 0; i < tableDescription.length; i++) {
        tableDescription[i].style.color = "#fff";
      }
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [orderId, orders]);

  return (
    <div className="admin submitted">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="ORDERS IN REVISION"
      />
      <AdminSidebar sidebar={sidebar} />
      <div className="container">
        <p className="empty"></p>
        <div className="search_container">
          <div className="input-group in-review">
            <input
              type="text"
              name="search"
              id=""
              placeholder="Filter by orderId or subject"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <button>
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <td>Order</td>
                <td>Client</td>
                <td>Writer</td>
                <td>Paper Details</td>
                <td>Work</td>
                <td>Requested At</td>
                <td>Submitted</td>
                <td>State</td>
                <td>Charges</td>
                <td>Deadline</td>
                <td>Penalties</td>
                <td>InRevisionFiles</td>
                <td>Original Instructions</td>
                <td>Revision Answers</td>
                <td>Original Answers</td>
                <td>Cancel Revision</td>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.length === 0 ? (
                <tr>
                  <td colSpan="8">
                    <p className="description">
                      There are no submitted orders!
                    </p>
                  </td>
                </tr>
              ) : (
                filteredOrders?.map(
                  (order) =>
                    order?.inRevision && (
                      <tr
                        key={order._id}
                        className="orderId"
                        id={`order-${order?.assignmentId?.orderId}`}
                      >
                        <td>
                          <p className="times">
                            <p className="superscript">Revisions</p>
                            <p>{order?.assignmentId?.inRevisionCounter}</p>
                          </p>
                          <p className="description">
                            {order?.assignmentId
                              ? order?.assignmentId?.orderId
                              : "N/A"}
                          </p>
                        </td>
                        <td>
                          <p
                            className="description"
                            style={{ minWidth: "80px" }}
                          >
                            {order?.assignmentId?.clientName}
                          </p>
                          {/* <p className="description">
                            Sh:{order?.assignmentId?.clientCharges}
                          </p> */}
                        </td>
                        <td>
                          <p
                            className="description"
                            style={{ minWidth: "80px" }}
                          >
                            {" "}
                            {order?.writerId
                              ? `${order?.writerId?.firstName} ${order?.writerId?.lastName}`
                              : "N/A"}
                          </p>
                          <p className="description">
                            {`ID:${order?.writerId.systemId} `}
                          </p>
                        </td>
                        <td>
                          <p
                            className="description"
                            style={{ color: "var(--blue)", minWidth: "270px" }}
                          >
                            Subject:{order?.assignmentId?.subject || "N/A"}
                          </p>
                          <p
                            className="description"
                            style={{ minWidth: "150px" }}
                          >
                            Category:{order?.assignmentId?.category || "N/A"}
                          </p>
                        </td>
                        <td>
                          <p
                            className="description"
                            style={{ minWidth: "140px" }}
                          >
                            Pages:{order?.assignmentId?.page || "N/A"}
                          </p>
                          <p className="description">
                            Words:{order?.assignmentId?.words || "N/A"}
                          </p>
                        </td>
                        <td>
                          <p
                            className="description"
                            style={{ minWidth: "100px" }}
                          >
                            {formatDate(
                              order?.assignmentId?.inRevisionRequestedAt
                            )}
                          </p>
                        </td>
                        <td>
                          {order.submittedAt && (
                            <p
                              className="description"
                              style={{ minWidth: "100px" }}
                            >
                              {formatDate(order?.submittedAt)}
                            </p>
                          )}
                        </td>
                        <td>
                          <p className="description">
                            {order?.category || "N/A"}
                          </p>
                        </td>
                        <td>
                          <p className="description">
                            {order?.assignmentId?.charges || "N/A"}
                          </p>
                        </td>
                        <td>
                          <p
                            className="description"
                            style={{ minWidth: "90px" }}
                          >
                            {order?.assignmentId.dateline.split("T")[0]}
                          </p>
                          <p className="description">
                            {countdowns[order._id]?.timeString}
                          </p>
                        </td>
                        <td>
                          <p className="description">
                            {order?.assignmentId?.penalty || "N/A"}
                          </p>
                        </td>
                        <td>
                          {order?.assignmentId?.inRevisionFiles?.length ===
                          0 ? (
                            <p className="description">No Files</p>
                          ) : (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  order?.assignmentId?.inRevisionFiles
                                )
                              }
                            >
                              Files
                            </button>
                          )}
                          <button
                            className="table-btn"
                            style={{
                              background: "var(--blue)",
                              border: "2px solid var(--blue)",
                              marginTop: "10px",
                            }}
                            onClick={() => {
                              handleRevisionOrder(
                                order?.assignmentId?.inRevisionComments
                              );
                            }}
                          >
                            view
                          </button>
                        </td>
                        <td>
                          {order?.assignmentId?.files?.length !== 0 && (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  order?.assignmentId?.files
                                )
                              }
                            >
                              Files
                            </button>
                          )}
                          {order?.assignmentId?.description && (
                            <button
                              className="table-btn"
                              style={{
                                marginTop: "1rem",
                                background: "var(--blue)",
                                border: "2px solid var(--white)",
                              }}
                              onClick={() => {
                                handleDescriptionModal();
                                handleWorkingOrder({
                                  subject: order?.assignmentId?.subject,
                                  description: order?.assignmentId?.description,
                                });
                              }}
                            >
                              Description
                            </button>
                          )}
                        </td>
                        <td>
                          {order?.assignmentId?.answerFiles.length !== 0 && (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  order?.assignmentId?.answerFiles
                                )
                              }
                            >
                              Files
                            </button>
                          )}
                          {order?.assignmentId?.revisionAnswersDescriptions
                            ?.length > 0 && (
                            <button
                              className="table-btn"
                              style={{
                                marginTop: "1rem",
                                background: "var(--blue)",
                                border: "2px solid var(--white)",
                              }}
                              onClick={() => {
                                handleRevisionOrder(
                                  order?.assignmentId
                                    ?.revisionAnswersDescriptions
                                );
                              }}
                            >
                              Description
                            </button>
                          )}
                        </td>
                        <td>
                          {order?.assignmentId?.originalAnswers?.length > 0 && (
                            <button
                              className="table-btn"
                              onClick={() =>
                                handleDownloadAllFiles(
                                  order?.assignmentId?.originalAnswers
                                )
                              }
                            >
                              Files
                            </button>
                          )}
                          {order?.assignmentId?.originalAnswerDescription && (
                            <button
                              className="table-btn"
                              style={{
                                marginTop: "1rem",
                                background: "var(--blue)",
                                border: "2px solid var(--white)",
                              }}
                              onClick={() => {
                                handleDescriptionModal();
                                handleWorkingOrder({
                                  subject: order?.assignmentId?.subject,
                                  description:
                                    order?.assignmentId
                                      ?.originalAnswerDescription,
                                });
                              }}
                            >
                              Description
                            </button>
                          )}
                        </td>

                        <td>
                          {order?.inRevision ? (
                            <button
                              className="table-btn"
                              onClick={() =>
                                cancelInRevision(
                                  order?._id,
                                  order?.assignmentId?._id
                                )
                              }
                            >
                              Cancel
                            </button>
                          ) : (
                            <button
                              className="table-btn setInRevision"
                              onClick={() => {
                                handleWorkingOrder({
                                  answerId: order?._id,
                                  email: order?.writerId?.email || "",
                                  orderId: order?.assignmentId?._id || "",
                                  subject: order?.assignmentId?.subject || "",
                                });
                                handleInRevisionModal();
                              }}
                              style={{
                                border: "2px solid var(--success-color)",
                              }}
                            >
                              Revision
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                )
              )}
            </tbody>
          </table>
        </div>

        <div
          className={`modal request-revision ${
            requestRevisionModal ? "active" : null
          }`}
        >
          <RequestRevisionModal
            handleInRevisionModal={handleInRevisionModal}
            workingOrder={workingOrder}
            fetchData={fetchData}
          />
        </div>
        <div
          className={`modal description-modal ${
            jobDescription ? "active" : null
          }`}
        >
          <JobDescription
            handleDescriptionModal={handleDescriptionModal}
            workingOrder={workingOrder}
          />
        </div>
      </div>

      {loading && <Preloader />}
      {displayDownloadModal && <DownloadFilesModal />}
      <CommentsModal />
      <RevisionHistoryModal />
    </div>
  );
};

export default AdminOrdersInRevision;
