import { useState, useEffect } from "react";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import AdminSidebar from "../../components/Admin/AdminSidebar";
import headers from "../../headers";
import Config from "../../Config";
import axios from "axios";
import { showLoading, hideLoading } from "../../Redux/features/AlertSlice";
import Preloader from "../../Preloader/Preloader";
import { useSelector, useDispatch } from "react-redux";
import { openDownloadModal } from "../../Redux/features/DownloadFilesSlice";
import "./CancelledOrders.css";
import DownloadFilesModal from "../../components/DownloadFilesModal";
import JobDescription from "../../components/Admin/JobDescription";

import CommentsModal from "../../components/CommentsModal/CommentsModal";

import { openCommentModal } from "../../Redux/features/CommentSlice";

import formatDate from "../../utils/FormatDate";

import { useParams } from "react-router-dom";

import RevisionHistoryModal from "../../components/RevisionHistoryModal/RevisionHistoryModal";

import { openRevisionHistoryModal } from "../../Redux/features/RevisionHistorySlice";

const CancelledOrders = () => {
  const loading = useSelector((state) => state.alerts.loading);
  const [sidebar, showSidebar] = useState(false);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [jobDescription, showJobDescription] = useState(false);
  const dispatch = useDispatch();
  const [workingOrder, setWorkingOrder] = useState([]);
  const displayDownloadModal = useSelector(
    (state) => state.downloadModal.displayDownloadModal
  );
  const handleWorkingOrder = (order) => {
    setWorkingOrder(order);
  };
  const handleDescriptionModal = () => {
    showJobDescription(!jobDescription);
  };
  const handleShowSidebar = () => {
    showSidebar(!sidebar);
  };
  const showCommentModal = (order) => {
    dispatch(openCommentModal(order));
  };
  const getCancelledOrders = async () => {
    try {
      dispatch(showLoading());
      const response = await axios.get(
        `${Config.baseUrl}/api/cancelled/orders/get`,
        {
          headers,
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        setCancelledOrders(response.data.data);
      }
    } catch (error) {
      console.log(
        `An error occurred fetching the cancelled orders from the backend`
      );
      dispatch(hideLoading());
    }
  };
  const handleRevisionOrder = (order) => {
    dispatch(openRevisionHistoryModal(order));
  };
  const handleFilesDownload = (files) => {
    dispatch(openDownloadModal(files));
  };
  const deleteCancelledOrder = async (orderId) => {
    try {
      dispatch(showLoading());
      const response = await axios.delete(
        `${Config.baseUrl}/api/cancelled/order/delete/${orderId}`,
        {
          headers,
        }
      );
      dispatch(hideLoading());
      if (response.data.success) {
        getCancelledOrders();
      } else {
        console.log(`There was an error deleting the record`);
      }
    } catch (error) {
      console.log(`There was an error deleting the record`);
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getCancelledOrders();
  }, []);

  const { orderId } = useParams();

  useEffect(() => {
    if (orderId && cancelledOrders.length > 0) {
      const element = document.getElementById(`order-${orderId}`);
      element.style.background = "var(--success-color)";
      const tableDescription = element.getElementsByTagName("p");
      for (let i = 0; i < tableDescription.length; i++) {
        tableDescription[i].style.color = "#fff";
      }
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [orderId, cancelledOrders]);
  return (
    <div className="admin cancelled-orders">
      <AdminNavbar
        sidebar={sidebar}
        handleShowSidebar={handleShowSidebar}
        title="CANCELLED ORDERS"
      />
      <AdminSidebar sidebar={sidebar} />
      <p className="empty"></p>
      {loading ? (
        <Preloader />
      ) : (
        <div className="container">
          <div className="table_wrapper">
            <table>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>CLIENT</td>
                  <td>WRITER</td>
                  <td>ORDER DETAILS</td>
                  <td>WORK</td>
                  <td>CHARGES</td>
                  <td>REFUND</td>
                  <td>CANCELLED AT</td>
                  <td>DEADLINE</td>
                  <td>Original Instructions</td>
                  <td>Revision Answers</td>
                  <td>InRevision instructions</td>
                  <td>Original Answers</td>
                  <td>Comments</td>
                  <td>Delete</td>
                </tr>
              </thead>
              {cancelledOrders?.length > 0 ? (
                <tbody>
                  {cancelledOrders.map((order) => (
                    <tr key={order._id} id={`order-${order?.systemId}`}>
                      <td>
                        <p className="description">{order?.systemId}</p>
                      </td>
                      <td>
                        <p className="description">{order?.clientName}</p>
                      </td>
                      <td>
                        <p className="description">
                          {order?.wasAssignedTo
                            ? `${order?.wasAssignedTo?.firstName} ${order?.wasAssignedTo?.lastName}`
                            : "No writer"}
                        </p>
                      </td>
                      <td>
                        <p className="description">Subject:{order?.subject}</p>
                        <p className="description">
                          Category:{order?.category}
                        </p>
                      </td>
                      <td>
                        <p className="description">Pages:{order?.page}</p>
                        <p className="description">Words:{order?.words}</p>
                      </td>
                      <td>
                        <p className="description">{order?.charges}</p>
                      </td>
                      <td>
                        <p className="description">{`${order?.refundPercentage}%`}</p>
                        <p className="description">{`Sh.${
                          (order?.refundPercentage / 100) * order?.charges
                        }`}</p>
                      </td>
                      <td>
                        <p className="description">
                          {formatDate(order?.cancelledAt)}
                        </p>
                      </td>
                      <td>
                        <p className="description">
                          {order?.dateline?.split("T")[0]} at {order?.time}
                        </p>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.description && (
                            <button
                              className="table-btn"
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                              onClick={() => {
                                handleDescriptionModal();
                                handleWorkingOrder({
                                  subject: order?.subject,
                                  description: order?.description,
                                });
                              }}
                            >
                              Description
                            </button>
                          )}

                          {order.files.length !== 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order.files);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>

                      <td>
                        <div className="description-buttons">
                          {order?.revisionAnswersDescriptions?.length > 0 && (
                            <button
                              className="table-btn"
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                              onClick={() => {
                                handleRevisionOrder(
                                  order?.revisionAnswersDescriptions
                                );
                              }}
                            >
                              Description
                            </button>
                          )}
                          {order?.answerFiles?.length > 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order?.answerFiles);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <p className="description">No Files</p>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.inRevisionComments?.length > 0 && (
                            <button
                              className="table-btn"
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                              onClick={() => {
                                handleRevisionOrder(order?.inRevisionComments);
                              }}
                            >
                              Description
                            </button>
                          )}
                          {order.inRevisionFiles.length > 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order?.inRevisionFiles);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <p className="description">No Files</p>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="description-buttons">
                          {order?.answerDescription && (
                            <button
                              className="table-btn"
                              style={{
                                background: "var(--blue)",
                                border: "2px solid var(--blue)",
                              }}
                              onClick={() => {
                                handleDescriptionModal();
                                handleWorkingOrder({
                                  subject: order?.subject,
                                  description: order?.answerDescription,
                                });
                              }}
                            >
                              Description
                            </button>
                          )}
                          {order?.originalAnswers?.length > 0 ? (
                            <button
                              className="table-btn"
                              onClick={() => {
                                handleFilesDownload(order?.originalAnswers);
                              }}
                            >
                              files
                            </button>
                          ) : (
                            <p className="description">No Files</p>
                          )}
                        </div>
                      </td>
                      <td>
                        <button
                          className="table-btn"
                          onClick={() => {
                            showCommentModal({ comment: order?.comments });
                          }}
                        >
                          view
                        </button>
                      </td>
                      <td>
                        <button
                          className="table-btn"
                          onClick={() => deleteCancelledOrder(order.orderId)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <p>There are no cancelled orders</p>
              )}
            </table>
          </div>
          <div
            className={`modal description-modal ${
              jobDescription ? "active" : null
            }`}
          >
            <JobDescription
              handleDescriptionModal={handleDescriptionModal}
              workingOrder={workingOrder}
            />
          </div>
        </div>
      )}
      {displayDownloadModal && <DownloadFilesModal />}

      <CommentsModal />
      <RevisionHistoryModal />
    </div>
  );
};

export default CancelledOrders;
