import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  displayCommentModal: false,

  data: null,

};

const CommentSlice = createSlice({

  name: "comment",

  initialState,

  reducers: {

    openCommentModal(state, action) {

      state.data = action.payload;

      state.displayCommentModal = true;

    },

    closeCommentModal(state) {

      state.data = null;

      state.displayCommentModal = false;
      
    },
  },
});

export const { openCommentModal, closeCommentModal } = CommentSlice.actions;

export default CommentSlice;
